import { useContext } from "react";

import { Header, NetworkStats } from "components/header";
import { CoinContext, PoolContext } from "libs/context";
import { Loading, SubHeader } from "layout";
import { useHistory, useParams } from "react-router-dom";
import { BlocksPayments, TopMiners, PoolChart, QuickConnect } from "components/homepage";
import { Banner } from "components/elements";

interface ParamsProps {
  poolId: string;
}

const showBanner = true;

export function Pool() {
  const { pools, poolsLoading, pricesLoading } = useContext(CoinContext);
  const { poolId } = useParams<ParamsProps>();
  const history = useHistory();

  if (poolsLoading || pricesLoading) {
    return <Loading />;
  }

  function DonationBanner() {
    return (
      <Banner color="blue">
        <p>
          We've contributed 2,168 VTC in our{" "}
          <a className="underline" href="https://twitter.com/hashalot1/status/1606371782583324686?s=20&t=nI4AwIcc7UXtiuIw-jxlgw">
            last donation
          </a>{" "}
          on December 23rd, 2022.
        </p>
        <p>
          25% of pool fees are donated to{" "}
          <a className="underline" target="_blank" rel="noopener noreferrer" href="http://insight.vertcoin.org/address/355VEXgBvgqfBjLaAUtpjFWrXSGbkzxBM5">
            Vertcoin Core Developers
          </a>
          .
        </p>
      </Banner>
    );
  }

  // function NoticeBanner() {
  //   const down = moment.utc("2021-07-02T19:00:00+00:00");
  //   return (
  //     <Banner color="yellow">
  //       There will be planned server maintenance {down.fromNow()}. <br />
  //       (Fri Jul 02 19:00:00 2021 UTC)
  //       <br />
  //       Expected downtime is no more than 10 minutes.
  //     </Banner>
  //   );
  // }

  const pool = pools.find((pool) => pool.id === poolId);
  if (!pool) {
    history.push("/");
    return null;
  } else {
    return (
      <PoolContext.Provider value={{ pool }}>
        <Header />
        <SubHeader />
        <NetworkStats />
        <div className="grid grid-cols-1 gap-6 mb-6 lg:grid-cols-2">
          <PoolChart />
          <QuickConnect />
        </div>
        {showBanner && <DonationBanner />}
        <TopMiners />
        <BlocksPayments />
      </PoolContext.Provider>
    );
  }
}
