import { FaCheck } from "react-icons/fa";

export function GettingStartedStep() {
  return (
    <div className="p-6">
      <div>
        <h3 className="text-xl font-semibold leading-6 text-center text-gray-100" id="modal-headline">
          Getting Started
        </h3>
        <div className="mt-2">
          <p className="mb-4 text-sm text-center text-gray-300">There are two ways to get started mining Vertcoin.</p>
          <div className="flex items-baseline justify-between">
            <div className="flex items-baseline space-x-2">
              <h5 className="text-sm font-bold text-white">One-Click Miner</h5>
              <span className="text-xs text-gray-400">recommended</span>
            </div>
            <div>
              <a
                href="https://github.com/vertcoin-project/one-click-miner-vnext/releases"
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-blue-400 transition hover:text-white"
              >
                Download
              </a>
            </div>
          </div>
          <p className="mb-5 text-sm text-gray-300">
            By far the easiest way to get started. It was created by the Vertcoin Core developers and walks you through
            the entire mining process. It includes your:
          </p>
          <ul className="flex flex-col pl-4 mb-8 space-y-2 text-sm text-gray-300">
            <li>
              <FaCheck className="inline-block w-4 h-4 mr-1.5 text-green-500" />
              Vertcoin Wallet
            </li>
            <li>
              <FaCheck className="inline-block w-4 h-4 mr-1.5 text-green-500" />
              Vertcoin Miner
            </li>
            <li>
              <FaCheck className="inline-block w-4 h-4 mr-1.5 text-green-500" />
              Hashalot Support
            </li>
          </ul>

          <div className="flex items-baseline space-x-2">
            <h5 className="text-sm font-bold text-white">Install Core Wallet + VerthashMiner</h5>
            <span className="text-xs text-gray-400">advanced</span>
          </div>

          <p className="mb-4 text-sm text-gray-300">
            If you've mined before, you're probably wondering two things. Where's the wallet, and where is the miner?
          </p>
          <p className="text-sm text-gray-300">The rest of this guide was built for you, my friend.</p>
        </div>
      </div>
    </div>
  );
}
