import { useContext } from "react";

import { Pagination, Table, Tooltip } from "components/elements";
import { CoinContext, MinerContext, PoolContext } from "libs/context";
import { usePagination } from "libs/hooks";
import { getBlockRewardByBlockHeight, localizeCurrency, normalizeSpeed } from "libs/helpers";
import { Pool } from "libs/types";
import { FaQuestionCircle } from "react-icons/fa";

interface Worker {
  name: string;
  hashrate: number;
  sharesPerSecond: number;
}

export function Workers() {
  const { prices } = useContext(CoinContext);
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool not found!");

  const minerContext = useContext(MinerContext);
  if (!minerContext) throw new Error("Miner context was not found!");
  const { miner } = minerContext;

  const workers: Worker[] = Object.keys(miner.performance?.workers || []).map((workerName) => ({
    name: workerName,
    hashrate: miner.performance?.workers[workerName].hashrate || 0,
    sharesPerSecond: miner.performance?.workers[workerName].sharesPerSecond || 0,
  }));
  const { paginatedData, activePage, handlePageChange, lastPage } = usePagination({ data: workers, pageSize: 10 });

  function renderTotalText() {
    if (workers.length === 0) return "";
    if (workers.length === 1) return "1 Worker";
    return `${workers.length} Workers`;
  }

  function renderPageNumberText() {
    if (workers.length === 0) return "";
    return `Page ${activePage} of ${lastPage}`;
  }

  function renderRow(worker: Worker, pool: Pool) {
    const speed = normalizeSpeed(worker.hashrate, "MH");
    const networkSpeed = normalizeSpeed(pool.networkStats.networkHashrate, "MH");
    const networkSharePercent = speed / networkSpeed;
    const blockReward = getBlockRewardByBlockHeight(pool.networkStats.blockHeight);
    const coinsPerDay = ((60 * 24) / 2.5) * blockReward;

    return (
      <>
        <Table.Cell>{worker.name || <span className="text-gray-500">Unnamed</span>}</Table.Cell>
        <Table.Cell>
          {speed} <small className="text-gray-400">MH/s</small>
        </Table.Cell>
        <Table.Cell className="hidden sm:table-cell">{Math.floor(worker.sharesPerSecond * 60 * 100) / 100}</Table.Cell>
        <Table.Cell>{localizeCurrency(coinsPerDay * networkSharePercent * prices.VTCUSD, 2)}</Table.Cell>
      </>
    );
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Hashrate</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">Shares/Min</Table.HeaderCell>
          <Table.HeaderCell>
            <div className="inline-flex items-center space-x-1">
              <span className="hidden sm:inline-block">Earnings/Day</span>
              <span className="sm:hidden">Daily</span>
              <Tooltip
                className="w-60"
                trigger={
                  <span>
                    <FaQuestionCircle className="w-3.5 h-3.5 text-gray-500 hover:text-gray-400 transition" />
                  </span>
                }
              >
                <Tooltip.Header>An estimate of earnings based on each worker's hashrate vs. the total network hashrate.</Tooltip.Header>
              </Tooltip>
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {workers.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={5} className="text-center text-gray-400">
              No workers found.
            </Table.Cell>
          </Table.Row>
        ) : (
          paginatedData.map((worker, index) => <Table.Row key={index}>{renderRow(worker, pool)}</Table.Row>)
        )}
      </Table.Body>
      {workers.length !== 0 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8}>
              <div className="flex items-center justify-between">
                <div>{renderPageNumberText()}</div>
                <div>
                  <Pagination activePage={activePage} lastPage={lastPage} handlePageChange={handlePageChange} />
                </div>
                <div> {renderTotalText()}</div>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}
