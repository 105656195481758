import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Layout } from "layout";
import { Homepage, Pool } from "routes";
import { useCoingecko, usePools, useUIVersion } from "libs/hooks";
import { CoinContext } from "libs/context";
import { Miner } from "./Miner";
import { SearchResults } from "./SearchResults";

export function Routes() {
  const [pools, UIVersion, poolsLoading] = usePools({ interval: 150000 });
  useUIVersion(UIVersion);
  const [prices, pricesLoading] = useCoingecko({ interval: 60000 });

  return (
    <Router>
      <Switch>
        <Layout>
          <CoinContext.Provider value={{ pools, poolsLoading, prices, pricesLoading }}>
            <Route path="/" exact component={Homepage} />
            <Route path="/:poolId/miners/:address" exact component={Miner} />
            <Route path="/pools/search" exact component={SearchResults} />
            <Route path="/:poolId" exact component={Pool} />
          </CoinContext.Provider>
        </Layout>
      </Switch>
    </Router>
  );
}
