import { Button, Segment } from "components/elements";
import { SetupModal } from "components/header";
import { PoolContext } from "libs/context";
import { useContext } from "react";

export function QuickConnect() {
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Missing pool information!");

  let payoutDescription = "";
  if (pool.paymentProcessing.payoutScheme === "Solo") {
    payoutDescription = "You'll get the full block reward when you find a block, less any pool fees.";
  } else {
    payoutDescription = "Pooled mining with other miners, best for those without mining farms.";
  }
  return (
    <Segment className="relative h-80 sm:h-auto">
      <div className="flex items-end space-x-2">
        <Segment.Header>
          <span className="hidden sm:block-inline">Quick </span>Connect
        </Segment.Header>
        <Segment.Description className="text-xs text-gray-400 sm:text-sm space-x-2">
          <span>
            {pool.paymentProcessing.payoutScheme.toUpperCase()} - {pool.poolFeePercent.toFixed(0)}% Fee
          </span>
        </Segment.Description>
      </div>
      <p className="mb-4 text-sm text-gray-200">{payoutDescription}</p>
      <dl className="flex flex-col p-4 space-y-4 divide-y divide-gray-500 divide-opacity-20">
        <dd>
          <code className="p-1 text-xs bg-gray-900">
            <span className="text-gray-400">url: </span>stratum+tcp://mining.hashalot.net
          </code>
        </dd>
        <dd className="pt-3">
          <code className="p-1 text-xs bg-gray-900">
            <span className="text-gray-400">ports: </span>
            {Object.keys(pool.ports).map((port, index) => {
              if (index === 0) {
                return (
                  <span key={index} className="pr-2">
                    {port} (NYC - USA)
                  </span>
                );
              } else {
                return null;
              }
            })}
          </code>
        </dd>
        <dd className="pt-3">
          <code className="p-1 text-xs bg-gray-900">
            <span className="text-gray-400">user: </span>address.workername <span className="text-gray-500">|</span>{" "}
            <span className="text-gray-400">password: </span>x
          </code>
        </dd>
      </dl>
      <div className="items-baseline justify-between hidden p-6 space-x-6 sm:flex">
        <div className="w-full">
          <SetupModal
            trigger={
              <Button icon="rocket" className="w-full">
                Setup Guide
              </Button>
            }
          />
        </div>
        <div className="w-full">
          <a href="https://vertcoin.org" tabIndex={-1} target="_blank" rel="noopener noreferrer">
            <Button icon="check" color="green" className="w-full">
              Vertcoin.org
            </Button>
          </a>
        </div>
      </div>
    </Segment>
  );
}
