import { useState, useEffect } from "react";
import { Pool, Payment } from "libs/types";
import { PoolPaymentsResponse } from "libs/types/responses";
import axios from "axios";
import { baseURL } from "libs/constants/urls";

interface Props {
  interval?: number;
  pool: Pool;
  address?: string;
}
export function usePayments({ interval = 30000, pool, address }: Props): [payments: Payment[], loading: boolean] {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchPayments() {
      try {
        const size = 50;
        const url = address
          ? `${baseURL}/pools/${pool.id}/miners/${address}/payments?page=0&pageSize=50`
          : `${baseURL}/pools/${pool.id}/payments?page=0&pageSize=${size}&combined=${
              pool.paymentProcessing.payoutScheme === "Solo" ? "false" : "true"
            }`;
        const response: PoolPaymentsResponse = await axios.get(url);
        setPayments(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    const paymentsInterval = setInterval(() => {
      fetchPayments();
    }, interval);

    fetchPayments();
    setLoading(false);
    return () => clearInterval(paymentsInterval);
  }, [address, interval, pool.id, pool.paymentProcessing.payoutScheme]);

  return [payments, loading];
}
