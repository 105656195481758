import React, { ReactNode } from "react";
import { useOverlay, useModal, DismissButton } from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { VisuallyHidden } from "@react-aria/visually-hidden";

interface Props {
  title?: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const OverlayContent = React.forwardRef(
  ({ title, children, isOpen, onClose, className, ...otherProps }: Props, ref) => {
    if (!ref) throw new Error("Ref cannot be null");

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    let { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as React.RefObject<HTMLElement>
    );

    // Hide content outside the modal from screen readers.
    let { modalProps } = useModal();

    // Get props for the dialog and its title
    let { dialogProps, titleProps } = useDialog({}, ref as React.RefObject<HTMLElement>);

    return (
      <FocusScope restoreFocus>
        <div
          {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
          ref={ref as React.RefObject<HTMLDivElement>}
          className={className}
        >
          <VisuallyHidden>
            <h3 {...titleProps}>{title}</h3>
          </VisuallyHidden>
          {children}
          <DismissButton onDismiss={onClose} />
        </div>
      </FocusScope>
    );
  }
);
