import { forwardRef } from "react";
import { classNames } from "libs/helpers";
import { FaRocket, FaCheck, FaCloudDownloadAlt } from "react-icons/fa";
import { ThreeDots } from "./Loaders";

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: "blue" | "indigo" | "green";
  icon?: "rocket" | "check" | "download";
  loading?: boolean;
  disabled?: boolean;
}

export const Button = forwardRef(
  (
    { className, color = "indigo", icon, loading, disabled, children, ...props }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    let buttonColors = "";
    let iconBgColor = "";
    switch (color) {
      case "green":
        buttonColors = "text-white bg-green-600 group-hover:bg-green-700 focus:ring-green-500";
        iconBgColor = "bg-green-600 bg-opacity-90 group-hover:bg-opacity-90 group-hover:bg-green-700";
        break;
      case "indigo":
      case "blue":
        buttonColors = "text-white bg-blue-600 group-hover:bg-blue-700 focus:ring-blue-500";
        iconBgColor = "bg-blue-600 bg-opacity-90 group-hover:bg-opacity-90 group-hover:bg-blue-700";
        break;
      default:
        buttonColors = "text-white bg-indigo-600 group-hover:bg-indigo-700 focus:ring-indigo-500";
        iconBgColor = "bg-indigo-600 bg-opacity-90 group-hover:bg-opacity-90 group-hover:bg-indigo-700";
        break;
    }

    if (icon) {
      return (
        <button
          ref={ref}
          className={classNames(
            className,
            "group overflow-hidden inline-flex items-center border border-transparent shadow-sm text-xs sm:text-sm rounded-md focus:outline-none"
          )}
          {...props}
        >
          <div className={classNames(iconBgColor, "pl-3 pr-1 py-2")}>
            {icon === "rocket" && <FaRocket className={iconClasses} />}
            {icon === "check" && <FaCheck className={iconClasses} />}
            {icon === "download" && <FaCloudDownloadAlt className={iconClasses} />}
          </div>
          <div className={classNames(buttonColors, "px-3 py-2 w-full font-extrabold leading-4")}>{children}</div>
        </button>
      );
    } else {
      return (
        <button
          disabled={disabled}
          className={classNames(
            className,
            disabled && "opacity-50 pointer-events-none",
            buttonColors,
            "inline-flex w-full items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-semibold rounded-md focus:outline-none"
          )}
          {...props}
        >
          {loading ? (
            <div className="flex justify-around w-full">
              <ThreeDots className="w-5 h-4" />
            </div>
          ) : (
            <div className="w-full">{children}</div>
          )}
        </button>
      );
    }
  }
);

const iconClasses = "-ml-0.5 mr-2 h-4 w-4 text-white";
