import { Table } from "components/elements";
import { Loading } from "layout";
import { CoinContext } from "libs/context";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Header } from "components/header";
import VTCLogo from "images/coins/vtc.svg";
import RVNLogo from "images/coins/rvn.svg";
import { Pool } from "libs/types";
import { normalizeSpeed } from "libs/helpers";
import { utc } from "moment";
import { FaAngleRight } from "react-icons/fa";

export function Homepage() {
  const { pools, poolsLoading, pricesLoading } = useContext(CoinContext);

  if (poolsLoading || pricesLoading) {
    return <Loading />;
  }

  const pooledPools = pools.filter((pool) => !pool.id.includes("solo"));
  const soloPools = pools.filter((pool) => pool.id.includes("solo"));

  return (
    <div>
      <Header />
      <div className="pt-8">
        <div className="px-4 pb-5 border-b border-gray-800 sm:px-0">
          <h3 className="text-lg font-extrabold leading-6 text-white">Pooled Mining</h3>
          <p className="max-w-4xl mt-2 text-sm text-gray-400 sm:text-base">
            Join other miners to find blocks as a group. When anyone finds a block, everyone earns a piece of the
            reward.
          </p>
        </div>
        <div className="pb-16 mt-8">
          <PoolSection pools={pooledPools} />
        </div>
        <div className="px-4 pb-5 my-8 border-b border-gray-800 sm:px-0">
          <h3 className="text-lg font-extrabold leading-6 text-white">Solo Mining</h3>
          <p className="max-w-4xl mt-2 text-sm text-gray-400 sm:text-base">
            Mine on your own. You'll get the full block reward when you find a block, less any pool fees.
          </p>
        </div>
        <div className="pb-8 mt-8">
          <PoolSection pools={soloPools} />
        </div>
      </div>
      <FAQ />
    </div>
  );
}

function PoolSection({ pools }: { pools: Pool[] }) {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Coin</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">Miners</Table.HeaderCell>
          <Table.HeaderCell>Hashrate</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">Last Block</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">Fee</Table.HeaderCell>
          <Table.HeaderCell className="w-8"></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {pools.map((pool, index) => (
          <PoolRow key={index} pool={pool} logo={pool.id.includes("vtc") ? VTCLogo : RVNLogo} />
        ))}
      </Table.Body>
    </Table>
  );
}

function PoolRow({ pool, logo }: { pool: Pool; logo: string }) {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/${pool.id}`} className="text-blue-500 transition hover:text-white">
          <div className="flex items-center space-x-3">
            <div>
              <img src={logo} className="w-8 h-8" alt={pool.coin.name} />
            </div>
            <div className="flex flex-col">
              <h3>{pool.coin.name}</h3>
              <small className="text-gray-400 uppercase">
                {pool.coin.type.toLowerCase()} - {pool.coin.algorithm}
              </small>
            </div>
          </div>
        </Link>
      </Table.Cell>
      <Table.Cell className="hidden sm:table-cell">{pool.poolStats.connectedMiners.toLocaleString("en-US")}</Table.Cell>
      <Table.Cell>
        {normalizeSpeed(pool.poolStats.poolHashrate, "MH").toLocaleString("en-US", { maximumFractionDigits: 0 })}{" "}
        <small className="text-gray-400">MH/s</small>
        <div className="sm:hidden">
          <small className="text-gray-400">{pool.poolStats.connectedMiners.toLocaleString("en-US")} miners</small>
        </div>
      </Table.Cell>
      <Table.Cell className="hidden sm:table-cell">{utc(pool.lastPoolBlockTime).local().fromNow()}</Table.Cell>
      <Table.Cell className="hidden sm:table-cell">{pool.poolFeePercent}%</Table.Cell>
      <Table.Cell>
        <Link to={`/${pool.id}`} className="">
          <FaAngleRight className="w-6 h-6 text-gray-500 transition hover:text-gray-300" />
        </Link>
      </Table.Cell>
    </Table.Row>
  );
}

function FAQ() {
  return (
    <div className="px-4 pt-16 pb-8 mx-auto divide-y-2 divide-gray-800 max-w-7xl sm:pt-24 sm:px-6 lg:px-8">
      <h2 className="text-lg font-extrabold text-white sm:text-xl">Frequently asked questions</h2>
      <div className="pt-10 mt-6">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
          <div>
            <FAQ.Header>How often are balances paid?</FAQ.Header>
            <FAQ.Answer>
              Confirmed balances are processed once per hour. It takes 100 confirmations to confirm a block before
              balances are confirmed.
            </FAQ.Answer>
          </div>

          <div>
            <FAQ.Header>Who pays the transaction fees when balances are paid?</FAQ.Header>
            <FAQ.Answer>
              The transaction fees for all balance payments are paid by Hashalot and included in each pool's fee.
            </FAQ.Answer>
          </div>
        </dl>
      </div>
    </div>
  );
}

function FAQHeader({ children }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) {
  return <dt className="font-medium leading-6 text-white sm:text-md">{children}</dt>;
}
function FAQAnswer({ children }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) {
  return <dd className="mt-2 text-sm text-gray-400 sm:text-base">{children}</dd>;
}

FAQ.Header = FAQHeader;
FAQ.Answer = FAQAnswer;
