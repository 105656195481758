import { Step } from "../SetupModal";

interface Props {
  steps: Step[];
  handleStepChange: (index: number) => void;
  currentStepNumber: number;
}

enum Status {
  Complete,
  Current,
  Pending,
}

export function StepsMenu({ steps, currentStepNumber, handleStepChange }: Props) {
  function determineStatus(stepNumber: number, currentStep: number) {
    if (stepNumber === currentStep) return Status.Current;
    if (stepNumber > currentStep) return Status.Pending;
    return Status.Complete;
  }

  return (
    <div className="px-4 py-12 sm:px-6 lg:px-8">
      <nav className="flex justify-center" aria-label="Progress">
        <ol className="space-y-6">
          {steps.map((step) => (
            <li key={step.order}>
              <StepItem
                onClick={() => handleStepChange(step.order)}
                status={determineStatus(step.order, currentStepNumber)}
              >
                {step.label}
              </StepItem>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

interface StepItemProps {
  status: Status;
  onClick: () => void;
  children: React.ReactNode;
}

function StepItem({ status, onClick, children }: StepItemProps) {
  switch (status) {
    case Status.Complete:
      return <CompleteStep handleClick={onClick} children={children} />;
    case Status.Current:
      return <CurrentStep handleClick={onClick} children={children} />;
    case Status.Pending:
    default:
      return <PendingStep handleClick={onClick} children={children} />;
  }
}

interface StepTypeItem {
  handleClick: () => void;
  children: React.ReactNode;
}

function CompleteStep({ handleClick, children }: StepTypeItem) {
  return (
    <button onClick={() => handleClick()} className="group">
      <span className="flex items-start">
        <span className="relative flex items-center justify-center flex-shrink-0 w-5 h-5">
          {/* Heroicon name: solid/check-circle */}
          <svg
            className="w-full h-full text-blue-600 group-hover:text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <span className="ml-3 text-sm font-medium text-gray-400 group-hover:text-white">{children}</span>
      </span>
    </button>
  );
}

function PendingStep({ handleClick, children }: StepTypeItem) {
  return (
    <button onClick={() => handleClick()} className="group">
      <div className="flex items-start">
        <div className="relative flex items-center justify-center flex-shrink-0 w-5 h-5" aria-hidden="true">
          <div className="w-2 h-2 bg-gray-300 rounded-full"></div>
        </div>
        <p className="ml-3 text-sm font-medium text-gray-400 group-hover:text-white">{children}</p>
      </div>
    </button>
  );
}

function CurrentStep({ handleClick, children }: StepTypeItem) {
  return (
    <button onClick={() => handleClick()} className="flex items-start" aria-current="step">
      <span className="relative flex items-center justify-center flex-shrink-0 w-5 h-5" aria-hidden="true">
        <span className="absolute w-4 h-4 bg-blue-500 rounded-full"></span>
        <span className="relative block w-2 h-2 bg-white rounded-full"></span>
      </span>
      <span className="ml-3 text-sm font-semibold text-white">{children}</span>
    </button>
  );
}
