import { useContext } from "react";
import { CoinContext } from "libs/context";
import { Flash } from "components/elements";
import { localizeNumber } from "libs/helpers";

export function NetworkStats() {
  const { pools, prices } = useContext(CoinContext);
  const { networkDifficulty, networkHashrate, blockHeight } = pools[0].networkStats;

  return (
    <div className="grid grid-cols-2 mt-8 mb-8 sm:my-12 gap-y-8 md:gap-y-0 md:grid-cols-4">
      <Statistic value={blockHeight} label="Block Height" />
      <Statistic value={localizeNumber(networkDifficulty, 2)} label="Difficulty" />
      <Statistic value={localizeNumber(networkHashrate / 1000000, 0)} label="Network (MH/s)" />
      <Statistic
        value={prices.VTCUSD.toLocaleString("en-US", { currency: "USD", style: "currency" })}
        label={`${localizeNumber(Math.floor(prices.BTCVTC * 100000000), 0)} SATS`}
        format="currency"
      />
    </div>
  );
}

interface StatisticProps {
  value: string | number;
  label: string;
  format?: "currency" | "number";
}

function Statistic({ value, label }: StatisticProps) {
  return (
    <div className="flex flex-col items-center">
      <div className="text-3xl">
        <Flash key={value}>{value}</Flash>
      </div>
      <div className="font-bold text-gray-400 uppercase">
        <Flash key={label}>{label}</Flash>
      </div>
    </div>
  );
}
