import { PoolContext } from "libs/context";
import { useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export function Search() {
  const { pool } = useContext(PoolContext);
  const [search, setSearch] = useState<string>("");
  const history = useHistory();

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (search.length >= 6) {
      setSearch("");
      if (pool) {
        history.push(`/${pool.id}/miners/${search}`);
      } else {
        history.push(`/pools/search?address=${search}`);
      }
    }
  }

  return (
    <div className="box-border relative flex items-center w-full py-2 pl-0 m-0 font-medium leading-6 text-center normal-case transition-colors ease-in-out bg-transparent border-0 border-gray-300 border-solid cursor-pointer bg-none hover:text-gray-700">
      <form onSubmit={handleSubmit} className="w-full sm:pr-10">
        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none -left-10 sm:left-0">
          <svg
            width="24"
            height="24"
            fill="none"
            className="box-border block leading-6 text-gray-500 align-middle ease-in-out border-solid hover:text-gray-600"
          >
            <path
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="font-medium text-center text-gray-600 border-0 border-gray-300 cursor-pointer"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          name="search"
          id="search"
          minLength={6}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className="font-medium text-white placeholder-gray-600 transition bg-transparent border-b border-transparent rounded-none sm:w-full w-44 sm:ml-10 focus:outline-none focus:border-gray-600"
          placeholder="Search miners by address"
        />
      </form>

      {search.length > 0 && (
        <div className="absolute inset-y-0 right-0 flex items-center text-gray-600">
          <button className="focus:outline-none" onClick={() => setSearch("")}>
            <FaTimes />
          </button>
        </div>
      )}
    </div>
  );
}
