import { classNames } from "libs/helpers";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color?: "blue" | "yellow";
}

export function Banner({ color = "blue", children, ...props }: Props) {
  let colorClasses = "bg-blue-600 border-blue-400 text-blue-100";
  switch (color) {
    case "yellow":
      colorClasses = "bg-yellow-700 border-yellow-800 text-yellow-100";
      break;
    default:
      break;
  }
  return (
    <div className={classNames("p-4 mb-6 sm:rounded-md", colorClasses)} {...props}>
      <div className="ml-3">
        <p className="text-sm font-medium text-center">{children}</p>
      </div>
    </div>
  );
}
