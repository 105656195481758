import { useState, useEffect } from "react";
import { Price } from "libs/types";
import { CoingeckoResponse } from "libs/types/responses";
import axios from "axios";

interface Props {
  interval?: number;
}
export function useCoingecko({ interval }: Props): [prices: Price, loading: boolean] {
  const [prices, setPrices] = useState<Price>({
    BTCVTC: 0,
    VTCUSD: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        let response: CoingeckoResponse = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=vertcoin&vs_currencies=btc%2Cusd`
        );

        if (response.status === 200) {
          setPrices({
            VTCUSD: response.data.vertcoin.usd,
            BTCVTC: response.data.vertcoin.btc,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    const refreshPool = setInterval(() => {
      fetchPrices();
    }, interval);

    fetchPrices();
    return () => clearInterval(refreshPool);
  }, [interval]);

  return [prices, loading];
}
