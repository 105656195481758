import { useState, useEffect } from "react";
import { BalanceChange } from "libs/types";
import axios from "axios";
import { BalanceChangesResponse } from "libs/types/responses";
import { baseURL } from "libs/constants/urls";

interface Props {
  interval?: number;
  poolId: string;
  address: string;
  limit?: number;
}
export function usePendingBalanceChanges({
  interval = 30000,
  poolId,
  address,
  limit,
}: Props): [balanceChanges: BalanceChange[], loading: boolean] {
  const [balanceChanges, setBalanceChanges] = useState<BalanceChange[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchBalanceChanges() {
      try {
        const size = limit || 100;
        const response: BalanceChangesResponse = await axios.get(
          `${baseURL}/pools/${poolId}/miners/${address}/pendingBalanceChanges?page=0&pageSize=${size}`
        );
        const balanceChanges = response.data;

        setBalanceChanges(balanceChanges);
      } catch (error) {
        console.log(error);
      }
    }

    const balanceChangesInterval = setInterval(() => {
      fetchBalanceChanges();
    }, interval);

    if (limit !== undefined && limit > 0) fetchBalanceChanges();
    setLoading(false);
    return () => clearInterval(balanceChangesInterval);
  }, [address, interval, limit, poolId]);

  return [balanceChanges, loading];
}
