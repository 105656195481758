import { CoinContext } from "libs/context";
import { useContext } from "react";

export function ConnectStep() {
  const { pools } = useContext(CoinContext);

  const pooled = pools.find((pool) => pool.id === "vtc");
  const solo = pools.find((pool) => pool.id === "vtc-solo");

  return (
    <div className="p-6">
      <div>
        <h3 className="text-lg font-semibold leading-6 text-center text-white">Connect to Hashalot</h3>
        <div className="mt-2">
          <p className="mb-4 text-sm text-center text-gray-200">There are two mining payout schemes available.</p>

          {pooled !== undefined && (
            <>
              <div className="flex items-baseline justify-between">
                <div className="flex items-baseline space-x-2">
                  <h5 className="text-sm font-bold text-white">Pooled Together</h5>
                  <span className="text-xs text-gray-400">{pooled.paymentProcessing.payoutScheme.toUpperCase()}</span>
                </div>
                <div>
                  <span className="text-xs text-gray-200">{pooled.poolFeePercent}% Fee</span>
                </div>
              </div>
              <p className="mb-4 text-sm text-gray-200">
                Choose to mine together in a pool. You are paid once a block is confirmed, and the amount is
                proportional to the work done vs. everyone else.
              </p>
              <p className="mb-4 text-sm text-gray-200">
                <code className="px-2 py-1 text-xs bg-gray-800 rounded">
                  stratum+tcp://mining.hashalot.net:{Object.keys(pooled.ports)[0]}
                </code>
                <br />
                <code className="px-2 py-1 text-xs bg-gray-800 rounded">user: address.workername</code>
                <br />
                <code className="px-2 py-1 text-xs bg-gray-800 rounded">password: x</code>
              </p>
            </>
          )}

          {solo !== undefined && (
            <>
              <div className="flex items-baseline justify-between">
                <div className="flex items-baseline space-x-2">
                  <h5 className="text-sm font-bold text-white">By Yourself</h5>
                  <span className="text-xs text-gray-400">{solo.paymentProcessing.payoutScheme.toUpperCase()}</span>
                </div>
                <div>
                  <span className="text-xs text-gray-200">{solo.poolFeePercent}% Fee</span>
                </div>
              </div>
              <p className="mb-4 text-sm text-gray-200">
                If you're feeling lucky (or have a substantial hashrate), you may choose to mine on your own. You're
                paid the full block reward, less any pool fees once the block is confirmed.
              </p>
              <p className="mb-4 text-sm text-gray-200">
                <code className="px-2 py-1 text-xs bg-gray-800 rounded">
                  stratum+tcp://mining.hashalot.net:{Object.keys(solo.ports)[0]}
                </code>
                <br />
                <code className="px-2 py-1 text-xs bg-gray-800 rounded">user: address.workername</code>
                <br />
                <code className="px-2 py-1 text-xs bg-gray-800 rounded">password: x</code>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
