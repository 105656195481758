import { useState, useEffect } from "react";
import { Balance } from "libs/types";
import axios from "axios";
import { SearchResponse } from "libs/types/responses/SearchResponse";
import { baseURL } from "libs/constants/urls";

interface Props {
  address?: string;
}
export function useBalances({ address }: Props): [balances: Balance[], loading: boolean] {
  const [balances, setBalances] = useState<Balance[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchBalances() {
      try {
        const url = `${baseURL}/search?address=${address}`;
        const response: SearchResponse = await axios.get(url);
        setBalances(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    if (address !== undefined) {
      fetchBalances();
    }
    setLoading(false);
  }, [address]);

  return [balances, loading];
}
