import React, { ReactElement, useState, ReactNode, useEffect, useRef } from "react";
import { usePopper } from "react-popper";
import { usePrevious, useOnClickOutside } from "libs/hooks";
import { Placement } from "@popperjs/core";
import { classNames } from "libs/helpers";
import { createPortal } from "react-dom";

interface Props {
  trigger: ReactElement;
  open: boolean;
  children: ReactNode;
  onClose?: (values?: any) => void;
  placement?: Placement;
  offset?: [number, number];

  /** Pass class names to the wrapper component */
  className?: string;
}

export function Popup({ trigger, open, onClose, children, placement = "top", offset, className }: Props) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const previouslyOpen = usePrevious(open);

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  // Watch for close, then run onClose
  useEffect(() => {
    if (previouslyOpen && !open) {
      if (onClose) onClose();
    }
  }, [onClose, open, previouslyOpen]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: offset,
        },
      },
    ],
  });

  return (
    <div>
      {React.cloneElement(trigger, {
        ref: setReferenceElement,
      })}
      {open &&
        createPortal(
          <div
            className={classNames(className, "relative z-20")}
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper}
          >
            <div ref={ref}>{children}</div>
          </div>,
          document.querySelector("body") as HTMLBodyElement
        )}
    </div>
  );
}
