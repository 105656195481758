import { classNames } from "libs/helpers";

interface TableProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> {
  paginated?: {
    perPage: number;
  };
}

export function Table({ className, paginated, children, ...props }: TableProps) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="relative inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border border-gray-700 shadow sm:rounded-lg">
            <table
              className={classNames("min-w-full divide-y divide-gray-500 divide-opacity-20", className)}
              {...props}
            >
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

interface TableHeaderProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> {}

export function TableHeader({ className, children, ...props }: TableHeaderProps) {
  return (
    <thead className={classNames(className, "bg-gray-800")} {...props}>
      {children}
    </thead>
  );
}

interface TableHeaderCellProps
  extends React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement> {}

export function TableHeaderCell({ className, children, ...props }: TableHeaderCellProps) {
  return (
    <th
      scope="col"
      className={classNames(className, "px-6 py-3 text-left text-xs font-bold text-gray-400 uppercase tracking-wider")}
      {...props}
    >
      {children}
    </th>
  );
}

interface TableRowProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {}

export function TableRow({ className, children, ...props }: TableRowProps) {
  return (
    <tr className={classNames(className, "")} {...props}>
      {children}
    </tr>
  );
}

interface TableBodyProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> {}

export function TableBody({ className, children, ...props }: TableBodyProps) {
  return (
    <tbody className={classNames(className, "bg-gray-800 divide-y divide-gray-500 divide-opacity-20")} {...props}>
      {children}
    </tbody>
  );
}

interface TableCellProps
  extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {}

export function TableCell({ className, children, ...props }: TableCellProps) {
  return (
    <td className={classNames(className, "px-6 py-4 whitespace-nowrap text-sm font-medium text-white")} {...props}>
      {children}
    </td>
  );
}

interface TableFooterProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> {}

export function TableFooter({ className, children, ...props }: TableFooterProps) {
  return (
    <tfoot
      className={classNames(
        className,
        "px-6 py-3 text-left text-xs font-bold text-gray-400 uppercase tracking-wider bg-gray-800"
      )}
      {...props}
    >
      {children}
    </tfoot>
  );
}

Table.Header = TableHeader;
Table.HeaderCell = TableHeaderCell;
Table.Row = TableRow;
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Footer = TableFooter;
