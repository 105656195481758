import React from "react";
import { Pool, Price } from "libs/types";

export interface CoinContextProps {
  prices: Price;
  pricesLoading: boolean;
  pools: Pool[];
  poolsLoading: boolean;
}

export const CoinContext = React.createContext<CoinContextProps>({
  prices: {
    BTCVTC: 0,
    VTCUSD: 0,
  },
  pricesLoading: true,
  pools: [],
  poolsLoading: true,
});
