import { forwardRef } from "react";

interface ProgressProps {
  percent: number;
}

export const Progress = forwardRef(({ percent }: ProgressProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div className="w-20 overflow-hidden bg-gray-900 rounded bg-opacity-40" ref={ref}>
      <div
        style={{ width: percent < 45 ? "45%" : `${percent}%` }}
        className="relative px-2 py-1.5 overflow-hidden rounded bg-blue-500"
      >
        <div className="text-xs font-bold text-center text-white">{Math.floor(percent)}%</div>
        <div className="absolute bottom-0 left-0 w-full h-full bg-white rounded opacity-50 animate-progress" />
      </div>
    </div>
  );
});
