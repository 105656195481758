import { Modal } from "components/modals";

interface Props {
  trigger: React.ReactElement;
}

export function VideoModal({ trigger }: Props) {
  return (
    <Modal>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <Modal.Content>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:align-middle sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ width: "560px", height: "315px" }}
        >
          <iframe
            width="560"
            height="315"
            title="What is Vertcoin?"
            src="https://www.youtube.com/embed/JKW4GP7YqIc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal.Content>
    </Modal>
  );
}
