import { Modal } from "components/modals";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useState } from "react";
import { GettingStartedStep, MinerStep, WalletStep, StepsMenu, AddressStep, ConnectStep } from "./setupContent";

interface Props {
  trigger: React.ReactElement;
}

export interface Step {
  label: string;
  order: number;
}

export function SetupModal({ trigger }: Props) {
  let state = useOverlayTriggerState({});
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);

  const steps: Step[] = [
    {
      label: "Getting Started",
      order: 1,
    },
    {
      label: "Install Core Wallet",
      order: 2,
    },
    {
      label: "Generate Address",
      order: 3,
    },
    {
      label: "Download Miner",
      order: 4,
    },
    {
      label: "Connect to Hashalot",
      order: 5,
    },
  ];

  function handleStepChange(step: number) {
    setCurrentStepNumber(step);
  }

  function renderStepContent(currentStepNumber: number) {
    switch (currentStepNumber) {
      case 1:
        return <GettingStartedStep />;
      case 2:
        return <WalletStep />;
      case 3:
        return <AddressStep />;
      case 4:
        return <MinerStep />;
      case 5:
        return <ConnectStep />;
      default:
        break;
    }
  }

  return (
    <Modal state={state}>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <Modal.Content>
        <div className="flex p-6 space-x-6 bg-gray-900 divide-x divide-gray-700 divide-opacity-40">
          <StepsMenu steps={steps} currentStepNumber={currentStepNumber} handleStepChange={handleStepChange} />
          <div className="max-w-md p-2">{renderStepContent(currentStepNumber)}</div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
