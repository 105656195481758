import { Miner } from "libs/types";

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export function handlePaginationClick(
  itemsArray: [],
  pageSize: number,
  target: "first" | "previous" | "next" | "last",
  activePage: number,
  setActivePage: React.Dispatch<React.SetStateAction<number>>
) {
  let updatedActivePage = 1;

  switch (target) {
    case "first":
      updatedActivePage = 1;
      break;
    case "previous":
      updatedActivePage = activePage - 1;
      break;
    case "next":
      updatedActivePage = activePage + 1;
      break;
    case "last":
      updatedActivePage = Math.floor(itemsArray.length / pageSize);
      break;
    default:
      break;
  }

  setActivePage(updatedActivePage);
}

export function truncateMiddle(string: string, length: number) {
  return `${string.slice(0, 6)}...${string.slice(string.length - length, string.length)}`;
}

export function normalizeSpeed(hashrate: number, factor: "MH" | "GH" | "TH") {
  let normalizedSpeed = hashrate;
  switch (factor) {
    case "GH":
      normalizedSpeed = hashrate / 1000000000;
      break;
    case "TH":
      normalizedSpeed = hashrate / 1000000000000;
      break;
    case "MH":
    default:
      normalizedSpeed = hashrate / 1000000;
      break;
  }
  return Math.floor(normalizedSpeed * 100) / 100;
}

export function normalizeSpeedWithLabel(hashrate: number, factor: "MH" | "GH" | "TH", fractionDigits?: number) {
  let normalizedSpeed = hashrate;
  let speedLabel = "MH/s";

  switch (factor) {
    case "GH":
      normalizedSpeed = hashrate / 1000000000;
      speedLabel = "GH/s";
      break;
    case "TH":
      normalizedSpeed = hashrate / 1000000000000;
      speedLabel = "TH/s";
      break;
    case "MH":
    default:
      normalizedSpeed = hashrate / 1000000;
      break;
  }

  return (
    (Math.floor(normalizedSpeed * 100) / 100).toLocaleString("en-US", {
      minimumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
      maximumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
    }) +
    " " +
    speedLabel
  );
}

export function calculateTotalMinerHashrate(miner: Miner) {
  let totalHashrate = 0;
  if (miner.performance !== null) {
    Object.keys(miner.performance.workers).forEach((workerName) => (totalHashrate += miner.performance?.workers[workerName].hashrate || 0));
  }
  return totalHashrate;
}

export function localizeNumber(amount: number, min: number, max?: number) {
  const fractionOptions = { minimumFractionDigits: min, maximumFractionDigits: max === undefined ? min : max };
  return amount.toLocaleString("en-US", { ...fractionOptions });
}

export function localizeCurrency(amount: number, min: number, max?: number) {
  const fractionOptions = { minimumFractionDigits: min, maximumFractionDigits: max === undefined ? min : max };
  const currencyOptions = { currency: "USD", style: "currency" };
  return amount.toLocaleString("en-US", { ...fractionOptions, ...currencyOptions });
}

export function getBlockRewardByBlockHeight(blockHeight: number) {
  if (blockHeight > 1680000) {
    return 12.5;
  } else {
    return 25;
  }
}
