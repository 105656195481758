import { useState, useEffect } from "react";
import { Pool } from "libs/types";
import { PoolsResponse } from "libs/types/responses";
import axios from "axios";
import { baseURL } from "libs/constants/urls";

interface Props {
  interval?: number;
}
export function usePools({ interval }: Props): [pools: Pool[], UIVersion: string, loading: boolean] {
  const [pools, setPools] = useState<Pool[]>([]);
  const [UIVersion, setUIVersion] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response: PoolsResponse = await axios.get(`${baseURL}/pools`);
        let pools = response.data.pools;

        if (response) {
          setPools(pools);
          setUIVersion(response.data.UIVersion);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const refreshPool = setInterval(() => {
      fetchPools();
    }, interval);

    fetchPools();
    return () => clearInterval(refreshPool);
  }, [interval]);

  return [pools, UIVersion, loading];
}
