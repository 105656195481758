import { useContext } from "react";
import { Label, Progress, Table, Pagination, Tooltip } from "components/elements";
import { CoinContext, PoolContext } from "libs/context";
import { usePagination } from "libs/hooks";
import { Block, Pool } from "libs/types";
import VTCLogo from "images/coins/vtc.svg";
import { Link } from "react-router-dom";
import { utc } from "moment";
import { FaQuestionCircle } from "react-icons/fa";

interface Props {
  blocks: Block[];
}

export function Blocks({ blocks }: Props) {
  const { prices } = useContext(CoinContext);
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool is missing!");

  const { paginatedData, activePage, handlePageChange, lastPage } = usePagination({ data: blocks, pageSize: 10 });

  // Column sorting
  // const [direction, setDirection] = useState<"ascending" | "descending">("ascending");
  // const [column, setColumn] = useState<string | null>(null);

  function renderBlocks(blocks: Block[], pool: Pool) {
    if (blocks.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={8} className="text-center">
            <p className="text-gray-400">No blocks, yet.</p>
          </Table.Cell>
        </Table.Row>
      );
    } else {
      return blocks.map((block) => renderBlockRow(block, pool));
    }
  }

  function renderBlockRow(block: Block, pool: Pool) {
    let progress;

    if (block.status === "orphaned") {
      progress = <Label color="orange">orphan</Label>;
    } else if (block.confirmationProgress === 0) {
      progress = <Label color="blue">{block.status}</Label>;
    } else {
      progress = <Progress percent={block.confirmationProgress * 100} />;
    }

    const totalMinutesRemaining = (1 - block.confirmationProgress) * 100 * 2.5;
    const hoursRemaining = Math.ceil(totalMinutesRemaining / 60) - 1;
    const minutesRemaining = Math.ceil(totalMinutesRemaining % 60);
    let humanTimeRemaining = "";
    if (hoursRemaining >= 1) {
      humanTimeRemaining += hoursRemaining.toFixed(0);
      hoursRemaining === 1 ? (humanTimeRemaining += " hr, ") : (humanTimeRemaining += " hrs, ");
    }
    humanTimeRemaining += `${minutesRemaining} min`;

    return (
      <Table.Row key={block.blockHeight}>
        <Table.Cell className="hidden sm:table-cell">
          <img src={VTCLogo} alt={block.poolId} />
        </Table.Cell>
        <Table.Cell>
          <a
            href={block.infoLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 transition hover:text-white"
          >
            {block.blockHeight}
          </a>
          <div className="sm:hidden">
            <small className="text-gray-400">{utc(block.created).local().fromNow()}</small>
          </div>
        </Table.Cell>
        <Table.Cell className="hidden sm:table-cell">
          {utc(block.created).local().format("hh:mm a")}
          <br />
          <small className="text-gray-400">{utc(block.created).local().fromNow()}</small>
        </Table.Cell>
        <Table.Cell className="hidden sm:table-cell">
          <Link to={`/${pool.id}/miners/${block.miner}`} className="text-blue-500 transition hover:text-white">
            {block.miner.slice(0, 6)}...{block.miner.slice(block.miner.length - 6, block.miner.length)}
          </Link>
        </Table.Cell>
        <Table.Cell className="hidden sm:table-cell">{renderBlockReward(block)}</Table.Cell>
        <Table.Cell>
          {block.networkDifficulty.toFixed(4)}
          <div className="sm:hidden">{renderEffort(block)}</div>
        </Table.Cell>
        <Table.Cell className="hidden sm:table-cell">{renderEffort(block)}</Table.Cell>
        <Table.Cell>
          {block.confirmationProgress === 1 ? (
            <Label color="green">{block.status}</Label>
          ) : block.status === "pending" ? (
            <Tooltip trigger={progress}>
              <Tooltip.Header>Est. Time Remaining</Tooltip.Header>
              <Tooltip.Content>{humanTimeRemaining}</Tooltip.Content>
            </Tooltip>
          ) : (
            progress
          )}
        </Table.Cell>
      </Table.Row>
    );
  }

  function renderEffort(block: Block) {
    let effortColor;
    let effort = Math.floor(block.effort * 100);

    switch (true) {
      case effort <= 100:
        effortColor = "text-green-500";
        break;
      case effort > 100 && effort < 150:
        effortColor = "text-yellow-500";
        break;
      case effort >= 150:
        effortColor = "text-red-500";
        break;
      default:
        effortColor = "text-white";
        break;
    }

    if (block.status !== "orphaned" && block.confirmationProgress === 0) {
      return (
        <>
          <small className="text-gray-400 uppercase sm:hidden">pending</small>
          <Label color="blue" className="hidden sm:block">
            pending
          </Label>
        </>
      );
    } else {
      return <span className={effortColor}>{(block.effort * 100).toFixed(0)}%</span>;
    }
  }

  function renderTotalBlocksText() {
    if (blocks.length === 0) return "";
    if (blocks.length === 1) return "1 Block";
    return (
      <span>
        Last {blocks.length}
        <span className="hidden sm:inline"> Blocks</span>
      </span>
    );
  }

  function renderPageNumberText() {
    if (blocks.length === 0) return "";
    return `Page ${activePage} of ${lastPage}`;
  }

  function renderBlockReward(block: Block) {
    if (block.reward > 0 && block.status === "confirmed") return renderBlockAndPrice(block.reward);
    if (block.reward === 0 && block.status === "pending" && block.pendingReward > 0) {
      return renderBlockAndPrice(block.pendingReward);
    }

    if (block.status === "orphaned") {
      return renderBlockAndPrice(0);
    } else {
      return <Label color="blue">pending</Label>;
    }
  }

  function renderBlockAndPrice(reward: number) {
    return (
      <div>
        {reward.toFixed(2)} <small className="text-gray-400">VTC</small>
        <br />
        <small className="text-gray-400">
          ${" "}
          {(reward * prices.VTCUSD).toLocaleString("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </small>
      </div>
    );
  }

  // let data = sortBy(blocks, [column]) as [];
  // if (direction === "ascending") data.reverse();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="hidden sm:table-cell">Coin</Table.HeaderCell>
          <Table.HeaderCell>Block</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">When</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">Miner</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">Reward</Table.HeaderCell>
          <Table.HeaderCell>Difficulty</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">
            <div className="inline-flex items-center space-x-1">
              <span>Effort</span>
              <Tooltip
                className="w-60"
                trigger={
                  <span>
                    <FaQuestionCircle className="w-3.5 h-3.5 text-gray-500 hover:text-gray-400 transition" />
                  </span>
                }
              >
                <Tooltip.Header>
                  If a block has an effort under 100%, it was found faster than expected and is considered lucky.
                </Tooltip.Header>
              </Tooltip>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {blocks.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={8} className="text-center text-gray-400">
              No blocks, yet.
            </Table.Cell>
          </Table.Row>
        ) : (
          renderBlocks(paginatedData, pool)
        )}
      </Table.Body>
      {blocks.length !== 0 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8}>
              <div className="flex items-center justify-between">
                <div>{renderPageNumberText()}</div>
                <div>
                  <Pagination activePage={activePage} lastPage={lastPage} handlePageChange={handlePageChange} />
                </div>
                <div> {renderTotalBlocksText()}</div>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}
