import { classNames } from "libs/helpers";
import { useState } from "react";
import { Popup } from "./Popup";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  trigger: React.ReactElement;
}

export function Tooltip({ trigger, children, className, ...props }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="relative" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} {...props}>
      <Popup open={open} trigger={trigger} placement="top" offset={[0, 8]}>
        <div
          className={classNames(
            "p-2 text-white whitespace-pre-line transition origin-top-left transform bg-gray-900 rounded-md shadow-lg pointer-events-none bg-opacity-90",
            className
          )}
        >
          {children}
        </div>
      </Popup>
    </div>
  );
}

Tooltip.Header = TooltipHeader;
Tooltip.Content = TooltipContent;

function TooltipHeader({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
  return (
    <h1 className={classNames("text-sm text-bold", className)} {...props}>
      {children}
    </h1>
  );
}

function TooltipContent({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) {
  return (
    <p className={classNames("text-sm text-gray-400", className)} {...props}>
      {children}
    </p>
  );
}
