import { useState, useEffect } from "react";
import { Miner } from "libs/types";
import { MinerResponse } from "libs/types/responses";
import axios from "axios";
import { baseURL } from "libs/constants/urls";

interface Props {
  interval?: number;
  address: string;
  poolId: string;
}

interface StateProps {
  miner: Miner | null;
  loading: boolean;
}
export function useMiner({ interval = 300000, address, poolId }: Props): [miner: Miner | null, loading: boolean] {
  const [data, setData] = useState<StateProps>({
    miner: null,
    loading: true,
  });

  useEffect(() => {
    async function fetchMiner() {
      try {
        const response: MinerResponse = await axios.get(`${baseURL}/pools/${poolId}/miners/${address}`);

        if (response) {
          setData({ miner: response.data, loading: false });
        }
      } catch (error) {
        setData({ miner: null, loading: false });
        console.log(error);
      }
    }

    fetchMiner();

    const refreshMinerStats = setInterval(() => {
      fetchMiner();
    }, interval);

    return () => clearInterval(refreshMinerStats);
  }, [address, interval, poolId]);

  return [data.miner, data.loading];
}
