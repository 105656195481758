import { useState, useEffect } from "react";
const loggingEnabled = false;

export function useUIVersion(version: string) {
  const getLocalStorage = localStorage.getItem("hashalot-ui-version");
  const [localUIVersion, setLocalUIVersion] = useState<string | null>(getLocalStorage && JSON.parse(getLocalStorage));

  if (loggingEnabled) console.log("UI Versions: Server:", version, "| Local:", localUIVersion);
  // Refresh browser anytime the local UI version does not match server version
  useEffect(() => {
    let serverUIVersion = version;
    if (!serverUIVersion) return;
    if (!localUIVersion || localUIVersion !== serverUIVersion) {
      if (loggingEnabled) console.log("UI Version Outdated! Server:", serverUIVersion, "| Local:", localUIVersion);
      localStorage.setItem("hashalot-ui-version", JSON.stringify(serverUIVersion));
      setLocalUIVersion(serverUIVersion);
      window.location.reload();
    }
  }, [localUIVersion, version]);
}
