import { Flash, Segment } from "components/elements";
import { CoinContext, MinerContext, PoolContext } from "libs/context";
import { calculateTotalMinerHashrate, localizeCurrency, localizeNumber, normalizeSpeedWithLabel } from "libs/helpers";
import { sumBy } from "lodash";
import { useContext } from "react";
import { MinimumPayoutModal } from "./MinimumPayoutModal";

export function MinerSummary() {
  const { prices } = useContext(CoinContext);
  const { pool } = useContext(PoolContext);
  const minerContext = useContext(MinerContext);

  if (!pool) throw new Error("Pool is missing!");

  if (!minerContext) throw new Error("Miner context was not found!");
  const { miner, minerLoading, balanceChanges } = minerContext;

  let pendingBalance = sumBy(balanceChanges, "amount");

  let totalHashrate = calculateTotalMinerHashrate(miner);
  let speedText = normalizeSpeedWithLabel(totalHashrate, "MH");
  const minerPercentOfNetwork = totalHashrate / pool.networkStats.networkHashrate;
  const minerPercentOfNetworkString = localizeNumber(minerPercentOfNetwork * 100, minerPercentOfNetwork > 0.01 ? 1 : 3);
  const pendingBalanceString = localizeNumber(pendingBalance, 3);

  return (
    <div className="grid grid-cols-2 gap-4 mb-6 sm:grid-cols-4">
      <Segment>
        <Segment.Header>Total Hashrate</Segment.Header>
        {minerLoading ? (
          <SummaryLoading />
        ) : (
          <>
            <Segment.Description className="mb-2 text-xs text-gray-400 sm:text-sm">
              <Flash key={speedText}>{speedText}</Flash>
              {totalHashrate > 0 && <span className="hidden sm:inline"> | {miner.userAgent}</span>}
            </Segment.Description>
            <br />
            <Segment.Description className="text-xs text-gray-400 sm:text-sm">
              Your hashrate is{" "}
              <Flash key={`percent-network-${minerPercentOfNetworkString}%`}>{minerPercentOfNetworkString}%</Flash> of
              the entire {pool.coin.name} network.
            </Segment.Description>
          </>
        )}
      </Segment>

      <Segment>
        <Segment.Header>
          Pending <span className="hidden sm:inline-block">Balance</span>
        </Segment.Header>
        {minerLoading ? (
          <SummaryLoading />
        ) : (
          <>
            <Segment.Description className="mb-2 text-xs text-gray-400 sm:text-sm">
              <Flash key={`pending-balance-${pendingBalanceString}`}>{pendingBalanceString}</Flash> {pool.coin.type} |{" "}
              {localizeCurrency(prices.VTCUSD * pendingBalance, 2)}
            </Segment.Description>
            <br />
            <Segment.Description className="text-xs text-gray-400 sm:text-sm">
              After 100 confirmations, pending balances are confirmed.
            </Segment.Description>
          </>
        )}
      </Segment>

      <Segment>
        <Segment.Header>
          Confirmed <span className="hidden sm:inline-block">Balance</span>
        </Segment.Header>
        {minerLoading ? (
          <SummaryLoading />
        ) : (
          <>
            <Segment.Description className="mb-2 text-xs text-gray-400 sm:text-sm">
              <Flash key={localizeNumber(miner.balance, 3)}>{localizeNumber(miner.balance, 3)}</Flash> {pool.coin.type}{" "}
              | {localizeCurrency(miner.balance * prices.VTCUSD, 2)}
            </Segment.Description>
            <br />
            <Segment.Description className="text-xs text-gray-400 sm:text-sm">
              Balances are paid once per hour on amounts over <MinimumPayoutModal />.
            </Segment.Description>
          </>
        )}
      </Segment>

      <Segment>
        <Segment.Header>Total Paid</Segment.Header>
        {minerLoading ? (
          <SummaryLoading />
        ) : (
          <>
            <Segment.Description className="mb-2 text-xs text-gray-400 sm:text-sm">
              <Flash key={`twentyFour-${localizeNumber(miner.totalPaid, 3)}`}>
                {localizeNumber(miner.totalPaid, 3)}
              </Flash>{" "}
              VTC | {localizeCurrency(miner.totalPaid * prices.VTCUSD, 2)}
            </Segment.Description>
            <br />
            <Segment.Description className="text-xs text-gray-400 sm:text-sm">
              This address was paid{" "}
              <Flash key={localizeNumber(miner.twentyFourHoursPaid, 1)}>
                {localizeNumber(miner.twentyFourHoursPaid, 1)}
              </Flash>{" "}
              VTC in the last 24 hours.
            </Segment.Description>
          </>
        )}
      </Segment>
    </div>
  );
}

function SummaryLoading() {
  return (
    <div className="flex flex-col mt-2 space-y-2">
      <div className="w-full h-3.5 mb-1 bg-gray-600 rounded animate-pulse" />
      <div className="w-full h-3.5 bg-gray-600 rounded animate-pulse" />
      <div className="w-full h-3.5 bg-gray-600 rounded animate-pulse" />
    </div>
  );
}
