import { classNames } from "libs/helpers";
import { FaAngleLeft, FaAngleDoubleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";

interface Props {
  activePage: number;
  lastPage: number;
  handlePageChange: (target: "first" | "previous" | "next" | "last") => void;
}

const activePaginationClasses = "text-gray-400 transition hover:text-gray-200";
const disabledPaginationClasses = "text-gray-600 cursor-default";

export function Pagination({ activePage, lastPage, handlePageChange }: Props) {
  return (
    <div className="flex space-x-2">
      <button
        className={classNames(
          "focus:outline-none",
          activePage !== 1 ? activePaginationClasses : disabledPaginationClasses
        )}
        disabled={activePage === 1}
        onClick={() => handlePageChange("first")}
      >
        <FaAngleDoubleLeft className="w-5 h-5" />
      </button>
      <button
        className={classNames(
          "focus:outline-none",
          activePage !== 1 ? activePaginationClasses : disabledPaginationClasses
        )}
        disabled={activePage === 1}
        onClick={() => handlePageChange("previous")}
      >
        <FaAngleLeft className="w-5 h-5" />
      </button>
      <button
        className={classNames(
          "focus:outline-none",
          activePage !== lastPage ? activePaginationClasses : disabledPaginationClasses
        )}
        disabled={activePage === lastPage}
        onClick={() => handlePageChange("next")}
      >
        <FaAngleRight className="w-5 h-5" />
      </button>
      <button
        className={classNames(
          "focus:outline-none",
          activePage !== lastPage ? activePaginationClasses : disabledPaginationClasses
        )}
        disabled={activePage === lastPage}
        onClick={() => handlePageChange("last")}
      >
        <FaAngleDoubleRight className="w-5 h-5" />
      </button>
    </div>
  );
}
