import { useContext } from "react";
import { Line } from "react-chartjs-2";

import { Segment } from "components/elements";
import { MinerContext, PoolContext } from "libs/context";
import { defaultChartColors } from "libs/constants/chartColors";
import { calculateTotalMinerHashrate, normalizeSpeed, normalizeSpeedWithLabel, truncateMiddle } from "libs/helpers";
import { useParams } from "react-router-dom";
import { uniq } from "lodash";

interface ParamsProps {
  address: string;
}

export function WorkerChart() {
  const context = useContext(PoolContext);
  if (!context.pool) throw new Error("No pool found!");
  const minerContext = useContext(MinerContext);
  const { address } = useParams<ParamsProps>();

  if (!minerContext) throw new Error("Miner context was not found!");
  const { miner, minerLoading } = minerContext;

  const labels: string[] = [];
  let datasets: Chart.ChartDataSets[] = [];
  const allWorkerNames: string[] = [];

  // Get all workers during this stretch of performance samples
  miner.performanceSamples.forEach((sample) => {
    Object.keys(sample.workers).forEach((workerName) => {
      allWorkerNames.push(workerName);
    });
  });

  const workerNames = uniq(allWorkerNames);

  // Create datasets to store data in
  workerNames.forEach((name, index) => {
    let subtractFromIndex = Math.floor(index / defaultChartColors.length) * defaultChartColors.length;
    let colorIndex = index <= defaultChartColors.length - 1 ? index : index - subtractFromIndex;

    datasets.push({
      label: name === "" ? "unnamed" : name,
      borderColor: defaultChartColors[colorIndex],
      pointBackgroundColor: defaultChartColors[colorIndex],
      fill: false,
      data: [],
    });
  });

  // Create chart dataset
  miner.performanceSamples.forEach((sample) => {
    let time = convertToTime(sample.created);
    labels.push(time);

    workerNames.forEach((workerName) => {
      // What dataset are we working on?
      const datasetIndex = datasets.findIndex((set) => set.label === (workerName === "" ? "unnamed" : workerName));
      if (datasetIndex === -1) throw new Error("Should have found dataset!");

      // Does this worker existing in the current sample?
      const sampleIndex = Object.keys(sample.workers).findIndex((name) => name === workerName);

      if (sampleIndex === -1) {
        datasets[datasetIndex].data?.push(0);
      } else {
        datasets[datasetIndex].data?.push(normalizeSpeed(sample.workers[workerName].hashrate, "MH"));
      }
    });
  });

  function convertToTime(created: string) {
    return new Date(created).toLocaleTimeString("en-US", {
      timeZone: "utc",
      hour: "numeric",
    });
  }

  if (minerLoading) {
    return (
      <div>
        <h3>Chart loading...</h3>
      </div>
    );
  }

  let workerCount = miner.performance ? Object.keys(miner.performance.workers).length : 0;
  let totalHashrate = calculateTotalMinerHashrate(miner);
  const totalHashrateWithLabel = normalizeSpeedWithLabel(totalHashrate, "MH");

  return (
    <Segment className="mb-6">
      <div className="flex items-baseline justify-between mb-6">
        <div className="flex items-baseline space-x-2">
          <Segment.Header>Miner</Segment.Header>
          <Segment.Description className="text-xs text-gray-400 sm:text-sm">
            {truncateMiddle(address, 6)}
          </Segment.Description>
        </div>
        <div className="flex items-baseline space-x-2">
          <Segment.Description className="text-xs text-gray-400 sm:text-sm">
            {workerCount} Worker{workerCount > 1 ? "s" : ""}
          </Segment.Description>
          <Segment.Header>{totalHashrateWithLabel}</Segment.Header>
        </div>
      </div>
      <div className="hidden pb-2 overflow-hidden sm:block">
        <Line data={{ labels, datasets }} options={generateChartOptions({ workerCount })} />
      </div>
      <div className="pb-2 overflow-hidden sm:hidden">
        <Line data={{ labels, datasets }} options={generateChartOptions({ workerCount, mobile: true })} />
      </div>
    </Segment>
  );
}

const textGray400 = "rgba(156, 163, 175, 1)";

function generateChartOptions({ workerCount, mobile }: { workerCount: number; mobile?: boolean }): Chart.ChartOptions {
  return {
    legend: {
      display: mobile || workerCount === 1 ? false : true,
      position: "bottom",
      labels: {
        fontColor: textGray400,
        padding: 6,
      },
    },
    scales: {
      gridLines: {
        display: false,
      },
      yAxes: [
        {
          id: "speed",
          gridLines: {
            drawTicks: false,
            drawBorder: false,
            color: "rgba(55, 65, 81, 0.4)",
          },
          ticks: {
            fontColor: textGray400,
            fontSize: 10,
            padding: 10,
            autoSkipPadding: 100,
            callback: (value) => `${value} MH/s`,
          },
          scaleLabel: { fontColor: textGray400 },
        },
      ],
      xAxes: [
        {
          display: true,
          gridLines: { drawTicks: false, drawOnChartArea: false },
          ticks: {
            fontColor: textGray400,
            fontSize: 10,
            padding: 20,
            maxRotation: 0,
            autoSkipPadding: 20,
          },
        },
      ],
    },
  };
}
