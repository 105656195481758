import { PoolContext } from "libs/context";
import { useBlocks, usePayments } from "libs/hooks";
import { useContext, useState } from "react";
import { Menu, Table } from "components/elements";
import { Blocks, Payments } from "../homepage";

export function BlocksPayments() {
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool is missing!");
  const [activeTab, setActiveTab] = useState<"Blocks" | "Payments">("Blocks");

  const [blocks, blocksLoading] = useBlocks({ interval: 60000, poolId: pool.id });
  const [payments, paymentsLoading] = usePayments({ interval: 300000, pool: pool });

  return (
    <div className="pb-6">
      <Menu
        options={[
          { label: "Blocks", active: activeTab === "Blocks", onClick: () => setActiveTab("Blocks") },
          { label: "Payments", active: activeTab === "Payments", onClick: () => setActiveTab("Payments") },
        ]}
      />
      {blocksLoading || paymentsLoading ? (
        <TableLoading />
      ) : (
        <>
          {activeTab === "Blocks" && <Blocks blocks={blocks} />}
          {activeTab === "Payments" && <Payments payments={payments} />}
        </>
      )}
    </div>
  );
}

function TableLoading() {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Coin</Table.HeaderCell>
          <Table.HeaderCell>Block</Table.HeaderCell>
          <Table.HeaderCell>When</Table.HeaderCell>
          <Table.HeaderCell>Miner</Table.HeaderCell>
          <Table.HeaderCell>Reward</Table.HeaderCell>
          <Table.HeaderCell>Difficulty</Table.HeaderCell>
          <Table.HeaderCell>Effort</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          {Array.from({ length: 8 }).map((_, index) => (
            <Table.Cell key={index}>
              <div className="w-full h-4 bg-gray-600 rounded animate-pulse" />
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
