import { useState } from "react";

interface Props {
  data: any[];
  pageSize: number;
}

interface ReturnProps {
  paginatedData: any[];
  handlePageChange: (target: "first" | "previous" | "next" | "last") => void;
  activePage: number;
  lastPage: number;
}

export function usePagination({ data, pageSize }: Props): ReturnProps {
  const [activePage, setActivePage] = useState<number>(1);
  const paginatedData = data.slice((activePage - 1) * pageSize, (activePage - 1) * pageSize + pageSize) as [];
  const lastPage = data.length === 0 ? 1 : Math.ceil(data.length / pageSize);

  function handlePageChange(target: "first" | "previous" | "next" | "last") {
    let updatedActivePage = 1;
    switch (target) {
      case "first":
        updatedActivePage = 1;
        break;
      case "previous":
        updatedActivePage = activePage - 1;
        break;
      case "next":
        updatedActivePage = activePage + 1;
        break;
      case "last":
        updatedActivePage = Math.floor(data.length / pageSize);
        break;
      default:
        break;
    }
    setActivePage(updatedActivePage);
  }

  return { paginatedData, handlePageChange, activePage, lastPage };
}
