import { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";

import { Segment } from "components/elements";
import { PoolContext } from "libs/context";
import { PoolPerformanceResponse } from "libs/types/responses";

import { normalizeSpeedWithLabel } from "libs/helpers";
import { baseURL } from "libs/constants/urls";

const interval = 180000; //sec

export function PoolChart() {
  const context = useContext(PoolContext);
  if (!context.pool) throw new Error("No pool found!");
  const { pool } = context;
  const min = useRef(0);
  const max = useRef(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartdata] = useState<Chart.ChartData>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchPoolStats = async () => {
      try {
        const url = `${baseURL}/pools`;
        const response: PoolPerformanceResponse = await axios.get(`${url}/${pool.id}/performance`);

        const labels: string[] = [];
        const speedDataset: Chart.ChartDataSets = {
          label: "Speed",
          data: [],
          pointBackgroundColor: "Rgba(59, 130, 246, 1)",
          backgroundColor: "rgba(59, 130, 246, .4)",
          borderWidth: 6,
          pointRadius: 2,
          yAxisID: "speed",
        };

        response.data.stats.forEach((stat, index) => {
          labels.push(
            new Date(stat.created).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              timeZone: "utc",
              hour: "numeric",
            })
          );
          const hashrate = Math.floor(stat.poolHashrate / 1000000);
          speedDataset.data?.push(hashrate);
          if (index === 0) {
            min.current = hashrate;
            max.current = hashrate;
          } else {
            if (hashrate < min.current) min.current = hashrate;
            if (hashrate > max.current) max.current = hashrate;
          }
        });

        setLoading(false);
        setChartdata({
          labels,
          datasets: [speedDataset],
        });
      } catch (error) {
        console.log(error);
      }
    };

    const refreshChart = setInterval(() => {
      fetchPoolStats();
    }, interval);

    fetchPoolStats();

    return () => clearInterval(refreshChart);
  }, [pool.id]);

  if (loading) {
    return (
      <div>
        <h3>Chart loading...</h3>
      </div>
    );
  }

  const chartOptions: Chart.ChartOptions = {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontColor: textGray400,
      },
    },
    scales: {
      gridLines: {
        display: false,
      },
      yAxes: [
        {
          id: "speed",
          gridLines: {
            drawTicks: false,
            drawBorder: false,
            color: "rgba(55, 65, 81, 0.4)",
          },
          ticks: {
            fontColor: textGray400,
            fontSize: 10,
            min: Math.floor(min.current / 100) * 100 - (min.current - 100 <= 0 ? 0 : 100),
            max: Math.ceil((max.current * 1.1) / 100) * 100,
            padding: 10,
            autoSkipPadding: 40,
            callback: (value) => `${value} MH/s`,
          },
          scaleLabel: { fontColor: textGray400 },
        },
      ],
      xAxes: [
        {
          display: false,
          gridLines: { drawTicks: false, drawOnChartArea: false },
          ticks: {
            fontColor: textGray400,
            fontSize: 10,
            padding: 100,
            maxRotation: 0,
          },
        },
      ],
    },
  };

  return (
    <Segment>
      <div className="flex items-baseline justify-between mb-6">
        <div className="flex items-baseline space-x-2">
          <Segment.Header>Total Hashrate</Segment.Header>
          <Segment.Description className="text-xs text-gray-400 sm:text-sm">
            {normalizeSpeedWithLabel(pool.poolStats.poolHashrate, "MH", 0)}
          </Segment.Description>
        </div>
        <Segment.Description className="text-xs text-gray-400 sm:text-sm">
          {pool.poolStats.connectedMiners} Miners
        </Segment.Description>
      </div>
      <div className="pb-2 overflow-hidden">
        <Line data={chartData} options={chartOptions} />
      </div>
    </Segment>
  );
}

const textGray400 = "#7f8ea4";
