import React, { useRef } from "react";
import { Transition } from "@headlessui/react";
import { OverlayContent } from "./OverlayContent";

import { useButton } from "@react-aria/button";
import { AriaButtonProps } from "@react-types/button";
import { useOverlayTriggerState, OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTrigger, OverlayContainer } from "@react-aria/overlays";
import { classNames } from "libs/helpers";

interface ModalContextProps {
  state: OverlayTriggerState;
  triggerRef: React.MutableRefObject<null>;
  overlayProps: React.HTMLAttributes<HTMLElement>;
  triggerProps: AriaButtonProps;
}
const ModalContext = React.createContext<ModalContextProps | null>(null);

interface Props {
  state?: OverlayTriggerState;
  children: React.ReactNode;
}

export function Modal(props: Props) {
  let state = useOverlayTriggerState({});
  let triggerRef = useRef(null);

  let { triggerProps, overlayProps } = useOverlayTrigger({ type: "dialog" }, state, triggerRef);

  return (
    <ModalContext.Provider value={{ state: props.state ? props.state : state, triggerRef, overlayProps, triggerProps }}>
      {props.children}
    </ModalContext.Provider>
  );
}

interface ModalTriggerProps {
  children: React.ReactElement;
}

function ModalTrigger({ children }: ModalTriggerProps) {
  const context = React.useContext(ModalContext);
  if (!context) throw new Error("Missing context");
  if (!context.state) throw new Error("Missing context state");
  if (!context.triggerRef) throw new Error("Missing context triggerRef");

  let { buttonProps } = useButton({ onPress: () => context.state.toggle() }, context.triggerRef);

  return React.cloneElement(React.Children.only(children), {
    ref: context.triggerRef,
    ...buttonProps,
    ...context.triggerProps,
  });
}

interface ModalContentProps {
  children: React.ReactNode;

  /** Pass any size classes to the Modal's content. */
  className?: string;

  onClose?: () => void;
}

function ModalContent({ children, className, onClose }: ModalContentProps) {
  const context = React.useContext(ModalContext);
  if (!context) throw new Error("Cannot call a trigger outside of a Modal component.");
  if (!context.state) throw new Error("State is missing from context!");
  if (!context.triggerRef) throw new Error("Trigger ref is missing from context!");

  const [overlayRef, setOverlayRef] = React.useState<React.RefObject<HTMLElement>>({ current: null });

  const callbackRef = React.useCallback((node) => {
    if (node !== null) {
      setOverlayRef({ current: node });
    }
  }, []);

  function handleClose(context: ModalContextProps) {
    if (onClose) onClose();
    context.state.close();
  }

  return (
    <Transition show={context.state.isOpen}>
      <OverlayContainer className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 transition-opacity"
          >
            <div className="absolute inset-0 bg-gray-900 opacity-80"></div>
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <OverlayContent
            {...context.overlayProps}
            ref={overlayRef.current ? overlayRef : callbackRef}
            isOpen={context.state.isOpen}
            onClose={() => handleClose(context)}
            className={classNames(
              className,
              "inline-block text-left align-bottom transform shadow-xl rounded-lg focus:outline-none sm:my-8 sm:align-middle"
            )}
          >
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {children}
            </Transition.Child>
          </OverlayContent>
        </div>
      </OverlayContainer>
    </Transition>
  );
}

interface ActionProps {
  children: (state: OverlayTriggerState) => React.ReactElement;
}

function ModalAction({ children }: ActionProps) {
  const context = React.useContext(ModalContext);
  if (!context) throw new Error("Cannot call Closeable outside of a Modal component.");
  if (!context.state) throw new Error("State is missing from context!");

  return children(context.state);
}

Modal.Content = ModalContent;
Modal.Trigger = ModalTrigger;
Modal.Action = ModalAction;
