import { Footer } from "components/footer";

interface Props {
  children: React.ReactNode;
}

export function Layout({ children }: Props) {
  return (
    <div className="bg-gray-900">
      <main className="max-w-6xl min-h-screen py-6 mx-auto text-white bg-gray-900 sm:px-6 lg:px-8">
        {children}
        <Footer />
      </main>
    </div>
  );
}
