import { Link } from "react-router-dom";
import { VideoModal } from "./VideoModal";
import { SetupModal } from "./SetupModal";
import { Search } from "./Search";
import VTCLogo from "images/coins/vtc.svg";
import { useContext } from "react";
import { PoolContext } from "libs/context";

export function Header() {
  const { pool } = useContext(PoolContext);

  return (
    <div>
      <div className="flex flex-col items-center justify-around mt-6 mb-8 space-y-8 sm:space-x-6 sm:space-y-0 md:flex-row md:justify-between sm:mt-0">
        <div className="relative sm:flex-none">
          <Link to="/" className="flex items-center space-x-3">
            {pool?.id.includes("vtc") && (
              <img
                src={VTCLogo}
                className="absolute top-0 w-8 h-8 -left-8 sm:relative sm:left-auto sm:top-auto"
                alt="Vertcoin"
              />
            )}
            <div className="flex flex-col space-y-1 align-middle sm:space-y-0 sm:pr-4">
              <h1 className="text-2xl font-bold leading-4 tracking-wide uppercase sm:text-xl">Hashalot</h1>
              {pool && (
                <small className="text-xs font-bold tracking-wide text-gray-400 uppercase">
                  {pool.coin.name} ({pool.coin.algorithm})
                </small>
              )}
            </div>
          </Link>
        </div>
        <div className="sm:flex-grow">
          <Search />
        </div>
        {pool?.id.includes("vtc") && (
          <div className="hidden text-sm tracking-wide sm:items-center md:flex flex-end">
            <VideoModal trigger={<button className={menuItemClasses}>What is Vertcoin?</button>} />
            <SetupModal trigger={<button className={menuItemClasses}>Setup Guide</button>} />
          </div>
        )}
      </div>
    </div>
  );
}

const menuItemClasses = "p-2 px-4 transition rounded-md hover:bg-gray-800";
