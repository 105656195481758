import { useContext } from "react";
import { Table, Pagination, Flash, Tooltip } from "components/elements";
import { CoinContext, PoolContext } from "libs/context";
import { Pool, TopMiner } from "libs/types";
import { Link } from "react-router-dom";
import { usePagination } from "libs/hooks";
import { getBlockRewardByBlockHeight, localizeNumber, truncateMiddle } from "libs/helpers";
import { FaQuestionCircle } from "react-icons/fa";

export function TopMiners() {
  const { prices } = useContext(CoinContext);
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool not found!");

  const { paginatedData, activePage, handlePageChange, lastPage } = usePagination({
    data: pool.topMiners,
    pageSize: 5,
  });

  // const [column, setColumn] = useState<string | null>(null);
  // const [direction, setDirection] = useState<"ascending" | "descending">("ascending");

  function normalizeSpeed(hashrate: number) {
    let speedLabel = "MH/s";
    let normalizedSpeed = hashrate / 1000000;
    if (normalizedSpeed > 1000) {
      normalizedSpeed = normalizedSpeed / 1000;
      speedLabel = "GH/s";
      if (normalizedSpeed > 1000) {
        normalizedSpeed = normalizedSpeed / 1000;
        speedLabel = "TH/s";
      }
    }
    return { normalizedSpeed, speedLabel };
  }

  function calculateRewards(pool: Pool, speed: number) {
    const poolFeePercent = pool.poolFeePercent;
    const blockTimeInMin = 2.5;
    const blockReward = getBlockRewardByBlockHeight(pool.networkStats.blockHeight);
    return (speed / pool.networkStats.networkHashrate) * ((60 * 24) / blockTimeInMin) * (1 - poolFeePercent / 100) * blockReward;
  }

  // function handleSort(clickedColumn: string) {
  //   if (column !== clickedColumn) {
  //     setColumn(clickedColumn);
  //     setDirection("ascending");
  //     return;
  //   }

  //   setDirection(direction === "ascending" ? "descending" : "ascending");
  // }

  // let data = sortBy(pool.topMiners, [column]) as [];
  // if (direction === "ascending") data.reverse();

  function renderTotalMinersText(pool: Pool) {
    if (pool.topMiners.length === 0) return "";
    if (pool.topMiners.length === 1) return "1 Miner";
    return (
      <span>
        Top {pool.topMiners.length}
        <span className="hidden sm:inline"> Miners</span>
      </span>
    );
  }

  function renderPageNumberText(pool: Pool) {
    if (pool.topMiners.length === 0) return "";
    return `Page ${activePage} of ${lastPage}`;
  }

  function calculateMinerProfit(pool: Pool, miner: TopMiner) {
    let dailyPayout = calculateRewards(pool, miner.hashrate);
    return {
      payoutDaily: dailyPayout,
      payoutDailyUSD: dailyPayout * prices.VTCUSD,
      payoutWeekly: dailyPayout * 7,
      payoutWeeklyUSD: dailyPayout * 7 * prices.VTCUSD,
    };
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Miners</Table.HeaderCell>
          <Table.HeaderCell className="text-right sm:text-left">Hashrate</Table.HeaderCell>
          <Table.HeaderCell className="hidden text-center sm:table-cell">
            <div className="inline-flex items-center space-x-1">
              <span>Daily VTC</span>
              <Tooltip
                className="w-60"
                trigger={
                  <span>
                    <FaQuestionCircle className="w-3.5 h-3.5 text-gray-500 hover:text-gray-400 transition" />
                  </span>
                }
              >
                <Tooltip.Header>Based on current hashrate and network difficulty. Actual amount may vary with luck.</Tooltip.Header>
              </Tooltip>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell className="hidden text-right sm:table-cell">Daily USD </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {pool.topMiners.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={4} className="text-center">
              <p className="text-gray-400">No miners found...</p>
            </Table.Cell>
          </Table.Row>
        ) : (
          paginatedData.map((miner, index) => {
            const profit = calculateMinerProfit(pool, miner);
            const { normalizedSpeed, speedLabel } = normalizeSpeed(miner.hashrate);
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <Link to={`${pool.id}/miners/${miner.miner}`} className="text-blue-500 transition hover:text-white">
                    <span className="sm:hidden">{truncateMiddle(miner.miner, 6)}</span>
                    <span className="hidden sm:block">{miner.miner}</span>
                  </Link>
                </Table.Cell>
                <Table.Cell className="text-right sm:text-left">
                  <span>
                    <Flash key={localizeNumber(normalizedSpeed, 2)}>{localizeNumber(normalizedSpeed, 2)}</Flash>{" "}
                    <small className="text-gray-400">{speedLabel}</small>
                  </span>
                </Table.Cell>
                <Table.Cell className="hidden text-center sm:table-cell">
                  {profit.payoutDaily.toLocaleString("en-US", {
                    maximumFractionDigits: profit.payoutDaily >= 1 ? 0 : 2,
                  })}{" "}
                  <small className="text-gray-400">VTC</small>
                </Table.Cell>
                <Table.Cell className="hidden text-right sm:table-cell">
                  <small className="text-gray-400">$</small>{" "}
                  {profit.payoutDailyUSD.toLocaleString("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
              </Table.Row>
            );
          })
        )}
      </Table.Body>
      {pool.topMiners.length !== 0 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>
              <div className="flex items-center justify-between">
                <div>{renderPageNumberText(pool)}</div>
                <Pagination activePage={activePage} lastPage={lastPage} handlePageChange={handlePageChange} />
                <div> {renderTotalMinersText(pool)}</div>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}
