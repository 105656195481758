import { useState, useEffect } from "react";
import { Block } from "libs/types";
import axios from "axios";
import { PoolBlocksResponse } from "libs/types/responses/PoolBlocksResponse";
import { baseURL } from "libs/constants/urls";

interface Props {
  interval?: number;
  poolId: string;
  address?: string;
  status?: "confirmed" | "pending" | "orphaned";
}
export function useBlocks({ interval = 30000, poolId, status }: Props): [blocks: Block[], loading: boolean] {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchBlocks() {
      try {
        const size = 50;
        let url = `${baseURL}/pools/${poolId}/blocks?page=0&pageSize=${size}`;
        if (status !== undefined) url += `&status=${status}`;
        const response: PoolBlocksResponse = await axios.get(url);
        const blocks = response.data;

        setBlocks(blocks);
      } catch (error) {
        console.log(error);
      }
    }

    const blocksInterval = setInterval(() => {
      fetchBlocks();
    }, interval);

    fetchBlocks();
    setLoading(false);
    return () => clearInterval(blocksInterval);
  }, [interval, poolId, status]);

  return [blocks, loading];
}
