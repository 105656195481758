import React from "react";
import { BalanceChange, Block, Miner } from "libs/types";

export const defaultMiner: Miner = {
  pendingShares: 0,
  balance: 0,
  pendingBalance: 0,
  minimumPayment: 0,
  totalPaid: 0,
  todayPaid: 0,
  twentyFourHoursPaid: 0,
  userAgent: null,
  lastPayment: null,
  lastPaymentLink: null,
  performance: null,
  performanceSamples: [],
};

interface ContextProps {
  miner: Miner;
  minerLoading: boolean;
  blocks: Block[];
  balanceChanges: BalanceChange[];
}

export const MinerContext = React.createContext<ContextProps | null>(null);
