import { forwardRef } from "react";
import { classNames } from "libs/helpers";

interface LabelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color: "green" | "blue" | "orange";
}

export const Label = forwardRef(
  ({ className, children, color, ...props }: LabelProps, ref: React.Ref<HTMLSpanElement>) => {
    let colors = "";
    switch (color) {
      case "green":
        colors = "bg-green-500 border-green-600";
        break;
      case "blue":
        colors = "bg-blue-500 borer-blue-600";
        break;
      case "orange":
        colors = "bg-yellow-500 border-yellow-600";
        break;
      default:
        break;
    }
    return (
      <span
        className={classNames(
          className,
          colors,
          "text-xs rounded border-solid border-0 inline-block font-bold leading-none m-0 py-2 px-3 text-left text-white normal-case whitespace-no-wrap"
        )}
        ref={ref}
        {...props}
      >
        {children}
      </span>
    );
  }
);
