export function AddressStep() {
  return (
    <div className="p-6">
      <div>
        <h3 className="text-lg font-semibold leading-6 text-center text-white">Generate a Mining Address</h3>
        <div className="mt-2">
          <p className="mb-4 text-sm text-center text-gray-200">Find the "Receiving" tab to get started.</p>
          <div className="flex justify-between">
            <h5 className="text-sm font-bold text-white">Choose a Label</h5>
            <span className="text-xs text-gray-200">optional</span>
          </div>
          <p className="mb-4 text-sm text-gray-200">
            You can add a label before generating the new address so that it's easily referenced anytime you receive
            Vertcoin.
          </p>
          <h5 className="text-sm font-bold text-white">Create New Receiving Address</h5>
          <p className="mb-4 text-sm text-gray-200">
            New addresses created by the core wallet are prepended with a{" "}
            <code className="px-2 py-1 bg-gray-800 rounded">vtc</code>. A new address will look similar to this:
          </p>
          <p className="mb-4 text-sm text-gray-200">
            <span className="px-2 py-1 bg-gray-800 rounded">vtc1q49qakykdxr7nzntu29sqkk00e2rrvwn5chnzpe</span>
          </p>
          <p className="text-sm text-gray-200">
            Save the address somewhere. You'll need it when configuring the miner.
          </p>
        </div>
      </div>
    </div>
  );
}
