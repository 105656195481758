import React from "react";
import { classNames } from "libs/helpers";

export function Segment({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className={classNames(className, "p-4 border border-gray-700 sm:rounded-md bg-gray-800")} {...props}>
      {children}
    </div>
  );
}

function SegmentHeader({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
  return (
    <h3 className={classNames(className, "font-bold text-md")} {...props}>
      {children}
    </h3>
  );
}

function SegmentDescription({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) {
  return (
    <small className={classNames(className, "font-medium")} {...props}>
      {children}
    </small>
  );
}

Segment.Header = SegmentHeader;
Segment.Description = SegmentDescription;
