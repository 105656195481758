import { Link } from "react-router-dom";
import PeepoKnifeImg from "images/peepoknife.png";

export function ErrorView() {
  return (
    <div className="flex items-center justify-around my-16">
      <div className="max-w-md">
        <h1 className="mb-1 text-3xl font-bold text-center text-gray-300">So...something went wrong.</h1>
        <p className="mb-6 text-center text-gray-400">You actually weren't supposed to see this page.</p>
        <p className="mb-6 text-center text-gray-400">
          <Link to="/" className="text-blue-400 transition hover:text-white">
            Click here to go back.
          </Link>{" "}
          In the meantime, I'll find out which one of my fingers is to blame for this.
        </p>
        <p className="flex justify-around">
          <img src={PeepoKnifeImg} alt="Peepe Knife" />
        </p>
      </div>
    </div>
  );
}
