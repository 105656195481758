import { Button } from "components/elements";

export function MinerStep() {
  return (
    <div className="p-6">
      <div>
        <h3 className="text-lg font-semibold leading-6 text-center text-gray-100" id="modal-headline">
          Install the Miner
        </h3>
        <div className="mt-2">
          <p className="mb-4 text-sm text-center text-gray-200">This miner is free to use and open-source.</p>

          <h5 className="text-sm font-bold text-white">Download VerthashMiner</h5>
          <p className="mb-4 text-sm text-gray-200">
            The Vertcoin Core developers funded the development of a miner built specifically for Verthash.
          </p>
          <div className="mt-5 mb-6 text-center sm:mt-6">
            <a
              tabIndex={-1}
              href="https://github.com/CryptoGraphics/VerthashMiner/releases"
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none"
            >
              <Button type="button" icon="download">
                Download from Github
              </Button>
            </a>
          </div>
          <div className="flex items-baseline justify-between">
            <div className="flex items-baseline space-x-2">
              <h5 className="text-sm font-bold text-white">Read the Quick Start Guide</h5>
              <span className="text-xs text-gray-400">seriously</span>
            </div>
            <div>
              <a
                href="https://github.com/CryptoGraphics/VerthashMiner"
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-blue-400 transition hover:text-white"
              >
                Visit Github
              </a>
            </div>
          </div>
          <p className="mb-4 text-sm text-gray-200">
            Included is an explation of how to generate the Verthash.conf file that's required to start the miner.
          </p>
          <p className="mb-4 text-sm text-gray-200">
            When you've become familiar with setting up the miner, continue to the next step for pool connection
            details.
          </p>
        </div>
      </div>
    </div>
  );
}
