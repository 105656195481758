import { Table, Pagination } from "components/elements";
import { Header } from "components/header";
import { Loading } from "layout";
import { CoinContext } from "libs/context";
import { useBalances, usePagination } from "libs/hooks";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { utc } from "moment";
import { parse } from "query-string";
import { truncateMiddle } from "libs/helpers";

export function SearchResults() {
  const { poolsLoading } = useContext(CoinContext);
  const location = useLocation();
  let { address } = parse(location.search);

  const [balances, loading] = useBalances({ address: address?.toString() });
  const { paginatedData, activePage, handlePageChange, lastPage } = usePagination({ data: balances, pageSize: 10 });

  if (poolsLoading || loading) return <Loading />;

  function renderTotalText() {
    if (balances.length === 0) return "";
    if (balances.length === 1) return "1 Result";
    return <span>{balances.length} Results</span>;
  }

  function renderPageNumberText() {
    if (balances.length === 0) return "";
    return `Page ${activePage} of ${lastPage}`;
  }

  return (
    <div className="mb-8">
      <Header />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell className="hidden sm:table-cell">Balance</Table.HeaderCell>
            <Table.HeaderCell>Where</Table.HeaderCell>
            <Table.HeaderCell className="hidden sm:table-cell">First Seen</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {balances.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan={5} className="text-center text-gray-500">
                No miners found.
              </Table.Cell>
            </Table.Row>
          ) : (
            paginatedData.map((balance, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Link
                    to={`/${balance.poolId}/miners/${balance.address}`}
                    className="text-blue-500 transition hover:text-white"
                  >
                    <span className="hidden sm:inline">{balance.address}</span>
                    <span className="sm:hidden">{truncateMiddle(balance.address, 6)}</span>
                  </Link>
                </Table.Cell>
                <Table.Cell className="hidden text-gray-200 sm:table-cell">
                  {balance.amount.toLocaleString("en-US", { minimumFractionDigits: 3, maximumFractionDigits: 3 })}{" "}
                  <small className="text-gray-400">VTC</small>
                </Table.Cell>
                <Table.Cell className="text-gray-200">{balance.poolId === "vtc" ? "POOLED" : "SOLO"}</Table.Cell>
                <Table.Cell className="hidden text-gray-200 sm:table-cell">
                  {utc(balance.created).local().fromNow()}
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
        {balances.length !== 0 && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={8}>
                <div className="flex items-center justify-between">
                  <div>{renderPageNumberText()}</div>
                  <div>
                    <Pagination activePage={activePage} lastPage={lastPage} handlePageChange={handlePageChange} />
                  </div>
                  <div> {renderTotalText()}</div>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  );
}
