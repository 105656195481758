import { Button } from "components/elements";

export function WalletStep() {
  return (
    <div className="p-6">
      <div>
        <h3 className="text-xl font-semibold leading-6 text-center text-gray-100" id="modal-headline">
          Install Core Wallet
        </h3>
        <div className="mt-2">
          <p className="mb-4 text-sm text-center text-gray-300">
            There are two major steps the Core wallet will do on a new installation.
          </p>
          <div className="flex justify-between">
            <h5 className="text-sm font-bold text-white">Generate Verthash.dat File</h5>
            <span className="text-xs text-gray-200">15-20 min</span>
          </div>
          <p className="mb-5 text-sm text-gray-300">
            The first thing the core wallet will do is generate this file. It's required to mine on the Vertcoin network
            using the new Verthash algorithm.
          </p>

          <div className="flex justify-between">
            <h5 className="text-sm font-bold text-white">Sync with Blockchain</h5>
            <span className="text-xs text-gray-200">2-3 hrs</span>
          </div>

          <p className="mb-8 text-sm text-gray-300">
            You'll also want to allow the wallet time to sync with the Vertcoin blockchain. This may take more than 2
            hours.
          </p>
          <div className="text-center sm:mt-6">
            <a
              tabIndex={-1}
              href="https://github.com/vertcoin-project/vertcoin-core/releases"
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none"
            >
              <Button type="button" icon="download">
                Download from Github
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
