import { classNames } from "libs/helpers";
import { Link } from "react-router-dom";

interface MenuProps {
  options: MenuOption[];
}

interface MenuOption {
  label: string;
  to?: string;
  active?: boolean;
  onClick?: () => void;
}

export function Menu({ options }: MenuProps) {
  return (
    <div className="m-4">
      <nav className="flex space-x-8" aria-label="Tabs">
        {options.map((option, index) =>
          option.to !== undefined ? (
            <Link
              key={index}
              to={option.to}
              className={classNames("focus:outline-none", option.active ? activeMenuClasses : menuClasses)}
            >
              {option.label}
            </Link>
          ) : (
            <button
              key={index}
              className={classNames("focus:outline-none", option.active ? activeMenuClasses : menuClasses)}
              onClick={option.onClick}
            >
              {option.label}
            </button>
          )
        )}
      </nav>
    </div>
  );
}

const menuClasses =
  "px-3 py-2 text-sm text-gray-400 font-medium rounded-md transition hover:text-white hover:bg-gray-800";
const activeMenuClasses = "px-3 py-2 text-sm font-medium text-white rounded-md";
