import { useContext } from "react";

import { Header, NetworkStats } from "components/header";
import { CoinContext, MinerContext, PoolContext } from "libs/context";
import { Loading, SubHeader } from "layout";
import { useParams } from "react-router-dom";
import { MinerSummary, WorkerChart, MinerTabs } from "components/miner";
import { useMiner } from "libs/hooks/useMiner";
import { ErrorView } from "components/views";
import { Banner } from "components/elements";
import { usePendingBalanceChanges } from "libs/hooks/usePendingBalanceChanges";
import { useBlocks } from "libs/hooks";

interface ParamsProps {
  poolId: string;
  address: string;
}

export function Miner() {
  const { pools, poolsLoading, pricesLoading } = useContext(CoinContext);
  const { poolId, address } = useParams<ParamsProps>();
  const [miner, minerLoading] = useMiner({ interval: 150000, address, poolId });
  const [blocks, blocksLoading] = useBlocks({ interval: 60000, poolId, status: "pending" });
  const [balanceChanges, balanceChangesLoading] = usePendingBalanceChanges({
    interval: 150000,
    poolId,
    address,
    limit: blocks.length,
  });

  if (poolsLoading || pricesLoading || minerLoading || blocksLoading || balanceChangesLoading) {
    return <Loading />;
  }

  if (!miner) {
    return <ErrorView />;
  } else {
    const showBanner = miner.userAgent?.includes("VerthashMiner/0.6.") ? true : false;
    const pool = pools.find((pool) => pool.id === poolId);
    if (!pool) throw new Error("Pool not found!");

    return (
      <PoolContext.Provider value={{ pool }}>
        <MinerContext.Provider value={{ miner, minerLoading, balanceChanges, blocks }}>
          <Header />
          <SubHeader />
          <NetworkStats />
          <WorkerChart />
          {showBanner && (
            <Banner color="yellow">
              Please{" "}
              <a
                href="https://github.com/CryptoGraphics/VerthashMiner/releases"
                className="font-medium underline"
                title="Verthash Miner Releases"
                target="_blank"
                rel="noopener noreferrer"
              >
                upgrade to VerthashMiner v.0.7.0
              </a>
              .
              <br />
              You will experience rejected shares on older versions.
            </Banner>
          )}
          <MinerSummary />
          <MinerTabs />
        </MinerContext.Provider>
      </PoolContext.Provider>
    );
  }
}
