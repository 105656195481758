import { useContext, useState } from "react";
import { Modal } from "components/modals";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { MinimumPaymentForm } from "./modalContent";
import { MinerContext, PoolContext } from "libs/context";
import { localizeNumber } from "libs/helpers";

export function MinimumPayoutModal() {
  let state = useOverlayTriggerState({});
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool is missing!");

  const minerContext = useContext(MinerContext);
  if (!minerContext) throw new Error("Miner details are missing!");
  const { miner } = minerContext;

  const [minimumPayment, setMinimumPayment] = useState<number>(
    miner.minimumPayment > pool.paymentProcessing.minimumPayment
      ? miner.minimumPayment
      : pool.paymentProcessing.minimumPayment
  );

  function updateMinimumPayment(amount: number) {
    setMinimumPayment(amount);
  }

  return (
    <div className="inline-block">
      <Modal state={state}>
        <Modal.Trigger>
          <button className="w-full underline transition focus:outline-none hover:text-white">
            {localizeNumber(minimumPayment, 0, 1)} {pool.coin.type}
          </button>
        </Modal.Trigger>
        <Modal.Content>
          <div
            className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-gray-900 rounded-lg shadow-xl sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <MinimumPaymentForm onChange={updateMinimumPayment} />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
