import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import { Pagination, Table } from "components/elements";
import { CoinContext, PoolContext } from "libs/context";
import { usePagination } from "libs/hooks";
import VTCLogo from "images/coins/vtc.svg";
import { FaLink } from "react-icons/fa";
import { Payment } from "libs/types";
import { utc } from "moment";
import { truncateMiddle } from "libs/helpers";

interface Props {
  payments: Payment[];
}

export function Payments({ payments }: Props) {
  const { prices } = useContext(CoinContext);
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool is missing!");

  const { address } = useParams<{ address: string }>();
  const { paginatedData, activePage, handlePageChange, lastPage } = usePagination({ data: payments, pageSize: 10 });

  // Column sorting
  //   const [direction, setDirection] = useState<"ascending" | "descending">("ascending");
  //   const [column, setColumn] = useState<string | null>(null);

  function renderTotalText() {
    if (payments.length === 0) return "";
    if (payments.length === 1) return "1 Payment";
    return (
      <span>
        Last {payments.length}
        <span className="hidden sm:inline"> Payments</span>
      </span>
    );
  }

  function renderPageNumberText() {
    if (payments.length === 0) return "";
    return `Page ${activePage} of ${lastPage}`;
  }

  function renderPaymentRow(payment: Payment) {
    return (
      <Table.Row key={payment.created}>
        <Table.Cell className="hidden sm:table-cell">
          <img src={VTCLogo} alt={payment.coin} />
        </Table.Cell>
        <Table.Cell className="hidden sm:table-cell">
          {utc(payment.created).local().format("h:mm a")}
          <br />
          <small className="text-gray-400">{utc(payment.created).local().fromNow()}</small>
        </Table.Cell>
        <Table.Cell>
          {payment.amount.toFixed(2)} <small className="text-gray-400">VTC</small>
          <br />
          <small className="hidden text-gray-400 sm:block">
            $
            {(payment.amount * prices.VTCUSD).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </small>
          <small className="text-gray-400 sm:hidden">{utc(payment.created).local().fromNow()}</small>
        </Table.Cell>
        {!address && (
          <Table.Cell>
            {pool?.paymentProcessing.payoutScheme === "Solo" ? (
              <Link to={`${pool.id}/miners/${payment.address}`} className="text-blue-500 hover:text-white">
                <span className="hidden sm:inline">{payment.address}</span>
                <span className="sm:hidden">{truncateMiddle(payment.address || "", 4)}</span>
              </Link>
            ) : (
              payment.addressCount
            )}
          </Table.Cell>
        )}
        <Table.Cell>
          <div className="flex justify-around">
            <a
              href={payment.transactionInfoLink}
              className="text-blue-500 transition hover:text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLink className="w-4 h-4" />
            </a>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  //   let data = sortBy(payments, [column]) as [];
  //   if (direction === "ascending") data.reverse();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="hidden sm:table-cell">Coin</Table.HeaderCell>
          <Table.HeaderCell className="hidden sm:table-cell">When</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          {!address && <Table.HeaderCell>{pool.id === "vtc-solo" ? "Miner" : "Miners"}</Table.HeaderCell>}
          <Table.HeaderCell className="text-center">
            <span className="sm:hidden">TX</span>
            <span className="hidden sm:inline">Transaction</span>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {payments.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={5} className="text-center text-gray-400">
              No payments, yet.
            </Table.Cell>
          </Table.Row>
        ) : (
          paginatedData.map((payment) => renderPaymentRow(payment))
        )}
      </Table.Body>
      {payments.length !== 0 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8}>
              <div className="flex items-center justify-between">
                <div>{renderPageNumberText()}</div>
                <div>
                  <Pagination activePage={activePage} lastPage={lastPage} handlePageChange={handlePageChange} />
                </div>
                <div> {renderTotalText()}</div>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}
