import React from "react";
import { Link, useParams } from "react-router-dom";

interface ParamsProps {
  poolId: string;
}

export function SubHeader() {
  const params = useParams<ParamsProps>();

  return (
    <div className="flex justify-between px-4 py-6 mb-8 space-x-4 text-sm bg-gray-800 border border-gray-700 sm:rounded-md">
      <div>
        <Link to={`/${params.poolId || "/"}`} className="p-2 px-4 text-gray-400 transition rounded-md hover:text-white">
          Home
        </Link>
      </div>
      <div>
        <Link to="/vtc" className={params.poolId === "vtc" ? activeMenuItemClasses : menuItemClasses}>
          POOLED
        </Link>
        <Link to="/vtc-solo" className={params.poolId === "vtc-solo" ? activeMenuItemClasses : menuItemClasses}>
          SOLO
        </Link>
      </div>
    </div>
  );
}

const menuItemClasses = "p-2 px-4 transition rounded-md text-gray-400 hover:text-white";
const activeMenuItemClasses = "p-2 px-4 text-white transition rounded-md bg-gray-900 bg-opacity-60";
