import { classNames } from "libs/helpers";
import { useEffect, useRef } from "react";

export function Flash({ children }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <div className="relative inline">
      <span>{children}</span>
      <div
        className={classNames(
          mounted.current && "animate-flash",
          "absolute top-0 left-0 w-full h-full rounded bg-yellow-200 opacity-0 bg-opacity-80"
        )}
      />
    </div>
  );
}
