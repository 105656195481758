import { useContext, useState } from "react";
import axios from "axios";
import { Button } from "components/elements";
import { baseURL } from "libs/constants/urls";
import { MinerContext, PoolContext } from "libs/context";
import { UpdateMinimumPaymentResponse } from "libs/types/responses";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";

interface Props {
  onChange: (amount: number) => void;
}

interface Values {
  ipAddress: string;
  amount: number;
}

interface Result {
  error: boolean;
  message: string;
}

export function MinimumPaymentForm({ onChange }: Props) {
  const { pool } = useContext(PoolContext);
  if (!pool) throw new Error("Pool is missing!");
  const minerContext = useContext(MinerContext);
  if (!minerContext) throw new Error("Miner is missing!");
  const { miner } = minerContext;

  const { address } = useParams<{ address: string }>();
  const [result, setResult] = useState<Result | null>(null);

  const initialValues: Values = {
    ipAddress: "",
    amount:
      miner.minimumPayment > pool.paymentProcessing.minimumPayment
        ? miner.minimumPayment
        : pool.paymentProcessing.minimumPayment,
  };

  const validationSchema = Yup.object().shape({
    ipAddress: Yup.string()
      .matches(/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/, {
        message: "This isn't an IP address.",
      })
      .required("An IP address is required."),
    amount: Yup.number()
      .required("An amount is required")
      .max(1000, "Please reduce to under 1,000")
      .min(
        pool.paymentProcessing.minimumPayment,
        `Must be at least the pool minimum of ${pool.paymentProcessing.minimumPayment}`
      ),
  });

  async function handleSubmit(values: Values, { setSubmitting }: FormikHelpers<Values>) {
    setResult(null);
    const updatedAmount = await UpdateMinimumPayout(values.ipAddress, values.amount);
    if (updatedAmount) onChange(updatedAmount);
    setSubmitting(false);
  }

  async function UpdateMinimumPayout(ipAddress: string, amount: number) {
    try {
      if (!pool) throw new Error("Pool is missing!");
      const url = `${baseURL}/pools/${pool.id}/miners/${address}/updateminimumpayment`;
      const newAmount = Math.round(amount * 10) / 10;
      const response: UpdateMinimumPaymentResponse = await axios.post(url, {
        poolId: pool.id,
        address,
        ipAddress,
        amount: newAmount,
      });
      if (!response.data.error) {
        setResult({
          error: false,
          message: `Your minimum payout has been updated to ${newAmount}.`,
        });
        return newAmount;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : "There was a problem.";
      if (message === "Request failed with status code 429") {
        setResult({ error: true, message: "Slow down!  Wait a minute and try again." });
      } else {
        setResult({ error: true, message });
      }
    }
  }

  return (
    <div>
      <div className="mt-3 text-center">
        <h3 className="text-xl font-semibold leading-6 text-center text-gray-100" id="modal-headline">
          Minimum Payout
        </h3>
        <div className="mt-1">
          <p className="text-sm text-center text-gray-300">
            You can adjust this miner's minimum payout by verifying your identity.
          </p>
        </div>
      </div>
      <div className="my-4 border-b border-gray-800" />
      <div className="mt-6">
        {result?.error && <ErrorAlert>{result.message}</ErrorAlert>}
        {result?.error === false && <SuccessAlert>{result.message}</SuccessAlert>}
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div>
                <label className="block text-sm font-medium text-gray-200">
                  Highest Performing Worker's Public IP Address
                </label>
                <div className="mt-1">
                  <Field
                    type="text"
                    id="ipAddress"
                    name="ipAddress"
                    className="block w-full px-2 py-2 text-white bg-gray-700 rounded-md shadow-sm sm:text-sm focus:outline-none"
                    placeholder="123.45.6.789"
                  />
                  {touched.ipAddress && errors.ipAddress && (
                    <p className="mt-1 text-xs text-red-600">{errors.ipAddress}</p>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-200">Minimum Payout Amount</label>
                <div className="mt-1">
                  <Field
                    type="text"
                    id="amount"
                    name="amount"
                    className="block w-full px-2 py-2 text-white bg-gray-700 rounded-md shadow-sm sm:text-sm focus:outline-none"
                    placeholder={pool.paymentProcessing.minimumPayment.toString()}
                  />
                  {touched.amount && errors.amount && <p className="mt-1 text-xs text-red-600">{errors.amount}</p>}
                </div>
              </div>

              <div className="pb-1 mt-5 sm:mt-6">
                <Button type="submit" loading={isSubmitting} color="blue" disabled={Object.keys(errors).length > 0}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

function ErrorAlert({
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className="p-2 mb-6 text-center" {...props}>
      <p className="text-sm font-medium text-red-600">{children}</p>
    </div>
  );
}

function SuccessAlert({
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className="p-2 mb-6 text-center rounded-md" {...props}>
      <p className="text-sm text-green-400">{children}</p>
    </div>
  );
}
