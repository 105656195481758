import { PoolContext } from "libs/context";
import { usePayments } from "libs/hooks";
import { useContext, useState } from "react";
import { Menu, Table } from "components/elements";
import { Payments } from "../homepage";
import { MinerBlocks } from "./MinerBlocks";
import { useParams } from "react-router-dom";
import { Workers } from "./Workers";

interface ParamsProps {
  address: string;
}

export function MinerTabs() {
  const { pool } = useContext(PoolContext);
  const { address } = useParams<ParamsProps>();
  if (!pool) throw new Error("Pool is missing!");
  const [activeTab, setActiveTab] = useState<"Workers" | "Blocks" | "Payments">("Blocks");

  const [payments, paymentsLoading] = usePayments({ interval: 60000, pool: pool, address: address });

  const tabs = [
    { label: "Blocks", active: activeTab === "Blocks", onClick: () => setActiveTab("Blocks") },
    { label: "Payments", active: activeTab === "Payments", onClick: () => setActiveTab("Payments") },
    { label: "Workers", active: activeTab === "Workers", onClick: () => setActiveTab("Workers") },
  ];

  return (
    <div className="pb-6">
      <Menu options={tabs} />
      {paymentsLoading ? (
        <TableLoading />
      ) : (
        <>
          {activeTab === "Workers" && <Workers />}
          {activeTab === "Blocks" && <MinerBlocks />}
          {activeTab === "Payments" && <Payments payments={payments} />}
        </>
      )}
    </div>
  );
}

function TableLoading() {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Coin</Table.HeaderCell>
          <Table.HeaderCell>Block</Table.HeaderCell>
          <Table.HeaderCell>When</Table.HeaderCell>
          <Table.HeaderCell>Miner</Table.HeaderCell>
          <Table.HeaderCell>Reward</Table.HeaderCell>
          <Table.HeaderCell>Difficulty</Table.HeaderCell>
          <Table.HeaderCell>Effort</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          {Array.from({ length: 8 }).map((_, index) => (
            <Table.Cell key={index}>
              <div className="w-full h-4 bg-gray-600 rounded animate-pulse" />
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
